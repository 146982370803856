/* eslint-disable */
/*export default [
  {
    title: '€ Euro',
    currency: {
      code: 'EUR',
      symbol: '€',
      name: 'Euro',
    },
  },
  {
    title: '£ Pound Sterling',
    currency: {
      code: 'GBP',
      symbol: '£',
      name: 'Pound Sterling',
    },
  },
  {
    title: '$ US Dollar',
    currency: {
      code: 'USD',
      symbol: '$',
      name: 'US Dollar',
    },
  },
  {
    title: '₽ Russian Ruble',
    currency: {
      code: 'RUB',
      symbol: '₽',
      name: 'Russian Ruble',
    },
  },
];*/

const data = [
  {
    title: 'Thai Bath',
    currency: {
      code: 'THB',
      symbol: 'THB',
      name: 'Thai Bath',
    },
  }
];
export default data;