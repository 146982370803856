/* eslint-disable */
import React, {useState,useEffect,useRef} from 'react';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import {updateShippingPrice,cartClearItem} from '../../store/cart';
import {orderAddData,orderClearItem} from '../../store/order';
import WidgetPayformSearch from '../../components/widgets/WidgetPayformSearch';
import { toast } from 'react-toastify';
import {APICalculate} from '../../api/thaipost';
import {APIConfig} from '../../api/config';
var validate = require("validate.js");

const ShopPagePayform = props =>{
  const {} = props;

  const [allowFile] = useState(['jpg', 'jpeg', 'png', 'pdf', 'zip', 'rar']);
  const mainFile = useRef();
  const [transfereeBankList,setTransfereeBankList] = useState();
  const [breadcrumb] = useState([
    { title: 'Home', url: '' },
    { title: 'Shopping Cart', url: '/shop/cart' },
    { title: 'Payment Form', url: '' },
  ]);
  const [payment,setPayment] = useState('bank');
  const [currentPayments] = useState(payments);
  const [schema] = useState({
    dataOrderId: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    firstName: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    lastName: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    email: {
      presence: { allowEmpty: false, message: 'is required' },
      email: true
    },
    transfereeBank: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    transferAmount: {
      presence: { allowEmpty: false, message: 'is required' },
      numericality: true
    },
    transferorBankName: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    transferorBankBranch: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    transferorBankAcctName: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    transferDate: {
      presence: { allowEmpty: false, message: 'is required' }
    },
    evidence: {
      presence: { allowEmpty: false, message: 'is required' }
    },
  });

  const [formData,setFormData] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  const initAPI = () =>{
    APIConfig('bank-account').then(resp=>{
      if(resp.status==200){
        return resp.json();
      }
    }).then(data=>{
      if(data?.data){
        var jsonBank = null;
        try{
          jsonBank = JSON.parse(data.data?.value || ''); 
        }catch(err){}
        setTransfereeBankList(jsonBank);
      }
    }).catch(err=>{
      console.log(err.message);
    });
  }

  /*const callAPICountry = () =>{
    fetch(process.env.API_ENDPOINT + 'config/getCountry', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: process.env.API_TIMEOUT
    }).then(resp=>{
      if(resp.status==200){
        return resp.json();
      }
    }).then(data=>{
      setCountry(data);
    }).catch(err=>{
      console.log(err.message);
    });
  }*/

  const handleSubmit = evt =>{
    evt.persist();

    var validateData = checkFormValidate();
    console.log(validateData);
    /*var isBillingValidate = checkBillingValidate();
    var isShippingValidate = true;
    var isFormDataValidate = checkFormDataValidate();
    if(formData.values?.differentAddr){
      isShippingValidate = checkShippingValidate();
    }

    if(isBillingValidate && isShippingValidate && isFormDataValidate){
      var orderParam = {
        billing:{
          firstName: billingAddress.values?.firstName || null,
          lastName: billingAddress.values?.lastName || null,
          companyName: billingAddress.values?.companyName || null,
          countryId: billingAddress.values?.countryId || null,
          streetAddress: billingAddress.values?.streetAddress || null,
          addressOther: billingAddress.values?.optAddress || null,
          city: billingAddress.values?.city || null,
          state: billingAddress.values?.state || null,
          zipCode: billingAddress.values?.postcode || null,
          email: billingAddress.values?.email || null,
          phone: billingAddress.values?.phone || null
        },
        subTotal: cart?.subtotal || 0,
        total: cart?.total || 0,
        status: 'N',
        notes: formData.values?.comment || null,
        email: billingAddress.values?.email || null,
        shippingType: cart?.shippingType || null,
      };
      if(formData.values?.differentAddr){
        orderParam.shipping = {
          firstName: shippingAddress.values?.firstName || null,
          lastName: shippingAddress.values?.lastName || null,
          companyName: shippingAddress.values?.companyName || null,
          countryId: shippingAddress.values?.countryId || null,
          streetAddress: shippingAddress.values?.streetAddress || null,
          addressOther: shippingAddress.values?.optAddress || null,
          city: shippingAddress.values?.city || null,
          state: shippingAddress.values?.state || null,
          zipCode: shippingAddress.values?.postcode || null,
          email: shippingAddress.values?.email || null,
          phone: shippingAddress.values?.phone || null
        }
      }else{
        orderParam.shipping = {
          firstName: billingAddress.values?.firstName || null,
          lastName: billingAddress.values?.lastName || null,
          companyName: billingAddress.values?.companyName || null,
          countryId: billingAddress.values?.countryId || null,
          streetAddress: billingAddress.values?.streetAddress || null,
          addressOther: billingAddress.values?.optAddress || null,
          city: billingAddress.values?.city || null,
          state: billingAddress.values?.state || null,
          zipCode: billingAddress.values?.postcode || null,
          email: billingAddress.values?.email || null,
          phone: billingAddress.values?.phone || null
        }
      }

      if(cart?.items && cart.items.length>0){
        orderParam.products = [];
        cart.items.map((item,indx)=>{
          orderParam.products.push({
            productId: item?.product?.productId || null,
            priceId: item?.priceItem?.priceId || null,
            qty: item?.quantity || 0,
            sort: (indx+1),
            status: true
          });
        });
      }

      fetch(process.env.API_ENDPOINT + 'order/saveOrder', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orderParam),
        timeout: process.env.API_TIMEOUT
      }).then(resp => {
        if (resp.status == 200) {
          return resp.json();
        }
      }).then(data => {
        if(data?.reasonCode && data.reasonCode=='0000' && data?.data){
          var billingCountry = "";
          var shippingCountry = "";

          country && country.map(item=>{
            if(billingAddress.values?.countryId==item.id){
              billingCountry = item.nicename
            }
            if(shippingAddress.values?.countryId==item.id){
              shippingCountry = item.nicename
            }
          });

          var jsonBillAddr = {
            firstName: billingAddress.values?.firstName || null,
            lastName: billingAddress.values?.lastName || null,
            country: billingCountry,
            postcode: billingAddress.values?.postcode || null,
            city: billingAddress.values?.city || null,
            address: billingAddress.values?.streetAddress || null,
            phone: billingAddress.values?.phone || null,
            email: billingAddress.values?.email || null
          }

          var orderData = {
            orderId: data.data,
            orderDate: moment().format('DD/MM/YYYY HH:mm'),
            shippingType: cart?.shippingType || 'N',
            subtotal: cart?.subtotal || 0.0,
            total: cart?.total || 0.0,
            billingAddress: jsonBillAddr
          }

          if(formData.values?.differentAddr){
            orderData.shippingAddress = {
              firstName: shippingAddress.values?.firstName || null,
              lastName: shippingAddress.values?.lastName || null,
              country: shippingCountry,
              postcode: shippingAddress.values?.postcode || null,
              city: shippingAddress.values?.city || null,
              address: shippingAddress.values?.streetAddress || null,
              phone: shippingAddress.values?.phone || null,
              email: shippingAddress.values?.email || null
            }
          }else{
            orderData.shippingAddress = jsonBillAddr
          }

          orderAddData(cart?.items || [],cart?.extraLines || [],orderData);
          props.history.push('/shop/checkout/success');
          cartClearItem();
        }
      }).catch((err) => {
        console.log(err.message);
      });
    }*/
  }

  const checkFormValidate = () =>{
    const errors = validate(formData.values, schema);
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {},
      touched:{
        dataOrderId: true,
        firstName: true,
        lastName: true,
        email: true,
        transfereeBank: true,
        transferAmount: true,
        transferorBankName: true,
        transferorBankBranch: true,
        transferorBankAcctName: true,
        transferDate: true,
        evidence: true
      }
    }));
    return errors ? false : true;
  }

  const hasError = field => {
    return formData.touched[field] && formData.errors[field] ? true : false;
  }

  const calculateShipping = paramCart =>{
    var totalWeight = 0;
    var paramIsWeight = true;
    var shippingLine = null;

    paramCart?.items && paramCart.items.map(item=>{
      if(item?.priceItem){
        if(item.priceItem?.priceWeight<1){
          paramIsWeight = false;
        }
        totalWeight += (item.priceItem?.priceWeight * item?.quantity);
      }
    });

    paramCart?.extraLines && paramCart.extraLines.map(item=>{
      if(item.type == 'shipping'){
        shippingLine = item;
      }
    });

    setIsWeight(paramIsWeight);
    if(paramIsWeight && totalWeight>0){
      APICalculate(totalWeight).then(resp => {
        if(resp.status==200){
          return resp.json();
        }
      }).then(data => {
        if(data.reasonCode && data.reasonCode=='0000' && data?.data && data.data.length>0){
          let shippingType = paramCart?.shippingType || 'N';
          var arrTmpPrice = [];
          data.data && data.data.map(item=>{
            if(item?.type==shippingType){
              arrTmpPrice.push(item?.price || 0);
            }
          });

          if(arrTmpPrice.length>0){
            arrTmpPrice.sort(function(a, b){return a - b});
            if(shippingLine && shippingLine.price!=arrTmpPrice[0]){
              updateShippingPrice(arrTmpPrice[0]);
            }
          }else{
            updateShippingPrice(0);
          }
        }else{
          updateShippingPrice(0);
        }
      }).catch((err) => {
        console.log(err.message);
      });
    }
  }

  const handleOnChange = evt =>{
    evt.persist();
    var vl = evt.target.value;
    var name = evt.target.name;

    setFormData(formData=>({
      ...formData,
      values:{
        ...formData.values,
        [name]: vl
      },
      touched:{
        ...formData.touched,
        [name]: true
      }
    }));
  }

  const handleFileChange = evt =>{
    evt.persist();
    var tmpFile = evt.target.files[0]
    if(tmpFile?.name){
      var arrFileName = tmpFile.name.split('.')
      if (allowFile.includes(arrFileName[arrFileName.length - 1].toLowerCase())) {
        setFormData(formData=>({
          ...formData,
          values:{
            ...formData.values,
            evidence: tmpFile.name
          },
          touched:{
            ...formData.touched,
            evidence: true
          }
        }));
      } else {
        toast.error(`Only *.jpg, *.png, *.pdf, *.zip, *.rar files are only allowed in size.`);
      }
    }
  }

  useEffect(()=>{
    const errors = validate(formData.values, schema);
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  },[formData.values]);

  useEffect(()=>{
    initAPI();
  },[]);

  return (
    <React.Fragment>
        <Helmet>
          <title>{`Payment Form — ${theme.name}`}</title>
        </Helmet>

        <PageHeader header="Payment Form" breadcrumb={breadcrumb} />

        <div className="checkout block">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="card mb-lg-0">
                  <div className="card-body">
                    <div className="form-group">
                      <label>Order Id / Customer Name</label>
                      <WidgetPayformSearch context="header" />
                    </div>

                    <div className="form-row">
                      <div className="form-group col-12">
                        <label>Order Id / Total Price&nbsp;<span className="text-danger">*</span></label>
                        <div style={{'display': 'block','width': '100%','border': '1px solid #ced4da',
                        'padding': '.375rem .75rem','fontSize': '1rem','fontWeight': '400','lineHeight': '1.25em'}}>
                          {formData.values?.dataOrderId || '-'}
                        </div>
                      </div>
                    </div>
                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-first-name">First Name&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('firstName')?' is-invalid':'')}
                        id="payform-first-name"
                        placeholder="First Name"
                        name={'firstName'}
                        value={formData.values?.firstName || ''}
                        onChange={handleOnChange} />
                        {hasError('firstName')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['firstName'][0] || ''}</span>
                        :null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-last-name">Last Name&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('lastName')?' is-invalid':'')}
                        id="payform-last-name"
                        placeholder="Last Name"
                        name={'lastName'}
                        value={formData.values?.lastName || ''}
                        onChange={handleOnChange} />
                        {hasError('lastName')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['lastName'][0] || ''}</span>
                        :null}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-email">Email address&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="email"
                        className={'form-control'+(hasError('email')?' is-invalid':'')}
                        id="payform-email"
                        placeholder="Email address"
                        name={'email'}
                        value={formData.values?.email || ''}
                        onChange={handleOnChange} />
                        {hasError('email')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['email'][0] || ''}</span>
                        :null}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-phone">Phone</label>
                        <input 
                        type="text" 
                        className="form-control" 
                        id="payform-phone" 
                        placeholder="Phone"
                        name={'phone'}
                        value={formData.values?.phone || ''}
                        onChange={handleOnChange} />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label>Transferee Bank&nbsp;<span className="text-danger">*</span></label>
                        <div style={{display: 'block',position: 'relative'}}>
                          <table cellPadding="0" cellSpacing="0" style={{
                          border: '#bbbbbb 1px solid',
                          width: '100%',
                          borderCollapse: 'collapse',
                          margin: '5px 0px',
                          fontSize: '0.9em' }}>
                            <thead>
                              <tr>
                                <th style={{backgroundColor: '#e4e4e4', borderBottom: '#bbbbbb 1px solid', borderRight: '#bbbbbb 1px solid'
                                , width: '40px', boxSizing: 'border-box'}}>&nbsp;</th>
                                <th style={{backgroundColor: '#e4e4e4', borderBottom: '#bbbbbb 1px solid', borderRight: '#bbbbbb 1px solid'
                                , width: '60px', boxSizing: 'border-box'}}>&nbsp;</th>
                                <th style={{ backgroundColor: '#e4e4e4', borderBottom: '#bbbbbb 1px solid', padding: '3px 5px' }}>Merchant Bank</th>
                              </tr>
                            </thead>
                            <tbody>
                            {transfereeBankList && transfereeBankList.map((item,indx)=>(
                              <tr style={{ verticalAlign: 'middle' }} key={indx}>
                                <td style={{
                                  borderBottom: '#bbbbbb 1px solid', borderRight: '#bbbbbb 1px solid',
                                  textAlign: 'center', padding: '5px 0px'
                                }}>
                                  <span className="filter-list__input input-radio">
                                    <span className="input-radio__body">
                                      <input
                                        className="input-radio__input"
                                        type="radio"
                                        name={'transfereeBank'}
                                        value={item?.bankName || ''}
                                        checked={formData.values?.transfereeBank?
                                          formData.values.transfereeBank==item?.bankName?true:false:false}
                                        onChange={handleOnChange}
                                      />
                                      <span className="input-radio__circle" />
                                    </span>
                                  </span>
                                </td>
                                <td style={{
                                  borderBottom: '#bbbbbb 1px solid', borderRight: '#bbbbbb 1px solid',
                                  textAlign: 'center', padding: '5px 0px'
                                }}><img src={item?.logo || null} style={{ width: '30px' }} /></td>
                                <td style={{ borderBottom: '#bbbbbb 1px solid', textAlign: 'left', padding: 5 }}>
                                  <div style={{ color: '#FF5722' }}>KasikornThai Bank</div>
                                  <div>Name: pcmultiinter co.,ltd</div>
                                  <div>Acct: 999-9-99999-9</div>
                                </td>
                              </tr>
                            ))}
                            </tbody>
                          </table>
                          {hasError('transfereeBank')?
                          <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transfereeBank'][0] || ''}</span>
                          :null}
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="payform-transferAmount">Transfer Amount&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('transferAmount')?' is-invalid':'')}
                        id="payform-transferAmount"
                        placeholder="0.0"
                        name={'transferAmount'}
                        value={formData.values?.transferAmount || ''}
                        onChange={handleOnChange} />
                        {hasError('transferAmount')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transferAmount'][0] || ''}</span>
                        :null}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-transferorBankName">Transferor Bank Name&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('transferorBankName')?' is-invalid':'')}
                        id="payform-transferorBankName"
                        placeholder="Transferor Bank Name"
                        name={'transferorBankName'}
                        value={formData.values?.transferorBankName || ''}
                        onChange={handleOnChange} />
                        {hasError('transferorBankName')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transferorBankName'][0] || ''}</span>
                        :null}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="payform-transferorBankBranch">Transferor Bank Branch&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('transferorBankBranch')?' is-invalid':'')}
                        id="payform-transferorBankBranch"
                        placeholder="Transferor Bank Branch"
                        name={'transferorBankBranch'}
                        value={formData.values?.transferorBankBranch || ''}
                        onChange={handleOnChange} />
                        {hasError('transferorBankBranch')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transferorBankBranch'][0] || ''}</span>
                        :null}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-transferorBankAcctName">Transferor Bank Account Name&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('transferorBankAcctName')?' is-invalid':'')}
                        id="payform-transferorBankAcctName"
                        placeholder="Transferor Bank Account Name"
                        name={'transferorBankAcctName'}
                        value={formData.values?.transferorBankAcctName || ''}
                        onChange={handleOnChange} />
                        {hasError('transferorBankAcctName')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transferorBankAcctName'][0] || ''}</span>
                        :null}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="payform-transferDate">Transfer Date/Time&nbsp;<span className="text-danger">*</span></label>
                        <input
                        type="text"
                        className={'form-control'+(hasError('transferDate')?' is-invalid':'')}
                        id="payform-transferDate"
                        placeholder="01/01/2020 11:00:00"
                        name={'transferDate'}
                        value={formData.values?.transferDate || ''}
                        onChange={handleOnChange} />
                        {hasError('transferDate')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['transferDate'][0] || ''}</span>
                        :null}
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-md-6">
                        <label htmlFor="payform-file">Payslip Evidence&nbsp;<span className="text-danger">*</span></label>
                        <div>
                          <input 
                          id="payform-file"
                          name="mainFile"
                          ref={mainFile}
                          type="file"
                          onChange={handleFileChange} />
                        </div>
                        <div style={{margin: '5px 0px'}}>
                          <span style={{fontSize: '0.8em'}} className="text-primary">
                            Evidence: Only *.jpg, *.png, *.pdf, *.zip, *.rar files are only allowed in size.
                          </span>
                        </div>
                        {hasError('evidence')?
                        <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['evidence'][0] || ''}</span>
                        :null}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="payform-note">Note</label>
                        <textarea rows="3" 
                        className="form-control" 
                        id="payform-note"
                        name={'note'}
                        value={formData.values?.note || ''}
                        placeholder="Note"
                        onChange={handleOnChange} />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col-12">
                        <button onClick={handleSubmit} type="button" className="btn btn-primary btn-xl btn-block">Submit Payform</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  updateShippingPrice,
  cartClearItem,
  orderAddData,
  orderClearItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPagePayform);
