/* eslint-disable */
// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import Rating from '../shared/Rating';
import { cartAddItemPrices } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Cross12Svg } from '../../svg';
import { url } from '../../services/utils';
import { wishlistRemoveItem } from '../../store/wishlist';

// data stubs
import theme from '../../data/theme';

function ShopPageWishlist(props) {
  const { wishlist, cartAddItemPrices, wishlistRemoveItem, compareAddItem } = props;
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Wishlist', url: '' },
  ];

  let content;

  if (wishlist.length) {
    const itemsList = wishlist.map((item) => {
      let image;

      if (item?.image) {
        image = (
          <div className="product-image">
            <Link to={url.product(item)} className="product-image__body">
              <img className="product-image__img" src={item?.image || ''} alt="" />
            </Link>
          </div>
        );
      }

      const renderAddToCarButton = ({ run, loading }) => {
        const classes = classNames('btn btn-primary btn-sm', {
          'btn-loading': loading,
        });

        return <button type="button" onClick={run} className={classes}>Add To Cart</button>;
      };

      const renderRemoveButton = ({ run, loading }) => {
        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
          'btn-loading': loading,
        });

        return <button type="button" onClick={run} className={classes} aria-label="Remove"><Cross12Svg /></button>;
      };

      return (
        <tr key={item.id} className="wishlist__row">
          <td className="wishlist__column wishlist__column--image">
            {image}
          </td>
          <td className="wishlist__column wishlist__column--product">
            <Link to={url.product(item)} className="wishlist__product-name">{item.name}</Link>
            {false && (
            <div className="wishlist__product-rating">
              <Rating value={item.rating} />
              <div className="wishlist__product-rating-legend">{`${item.reviews} Reviews`}</div>
            </div>
            )}
          </td>
          <td className="wishlist__column wishlist__column--stock">
            <div className="badge badge-success">In Stock</div>
          </td>
          <td className="wishlist__column wishlist__column--price"><Currency value={item.price} /></td>
          <td className="wishlist__column wishlist__column--tocart">
            {item?.priceFlag==true && (
            <AsyncAction
            action={() => cartAddItemPrices({
              productId: item?.id || null,
              productSku: item?.sku || null,
              productSlug: item?.slug || null,
              productName: item?.name || null,
              productSubtitle: item?.subtitle || null,
              productStatus: item?.status || null,
              productImage:{
                imageMain: item?.image || ''
              }
            }, {
              priceId: item?.priceId || null,
              priceName: item?.priceName || null,
              priceNumber: item?.price || 0,
              priceWeight: item?.priceWeight || 0
            }, [], 1)}
            render={renderAddToCarButton} />
            )}

            {!item?.priceFlag && (
            <AsyncAction
            action={() => compareAddItem(item)}
            render={({ run, loading }) => (
              <button
              title="Add to Quatation"
              type="button"
              onClick={run}
              className={classNames('btn btn-primary', {
                'btn-loading': loading,
              })}>
                Add To Quotation
              </button>
            )} />
            )}
          </td>
          <td className="wishlist__column wishlist__column--remove">
            <AsyncAction
              action={() => wishlistRemoveItem(item.id)}
              render={renderRemoveButton}
            />
          </td>
        </tr>
      );
    });

    content = (
      <div className="block">
        <div className="container">
          <table className="wishlist">
            <thead className="wishlist__head">
              <tr className="wishlist__row">
                <th className="wishlist__column wishlist__column--image">Image</th>
                <th className="wishlist__column wishlist__column--product">Product</th>
                <th className="wishlist__column wishlist__column--stock">Stock Status</th>
                <th className="wishlist__column wishlist__column--price">Price</th>
                <th className="wishlist__column wishlist__column--tocart" aria-label="Add to cart" />
                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
              </tr>
            </thead>
            <tbody className="wishlist__body">
              {itemsList}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="block block-empty">
        <div className="container">
          <div className="block-empty__body">
            <div className="block-empty__message">Your wish list is empty!</div>
            <div className="block-empty__actions">
              <Link to="/" className="btn btn-primary btn-sm">Continue</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Wish List — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header="Wishlist" breadcrumb={breadcrumb} />

      {content}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  wishlist: state.wishlist,
});

const mapDispatchToProps = {
  cartAddItemPrices,
  compareAddItem,
  wishlistRemoveItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopPageWishlist);
