/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {APIConfig} from '../../api/config';

const BlockMap = props => {
  const {} = props;
  const [contactMap, setContactMap] = useState();

  const callAPIConfigContactMap = () => {
    APIConfig('contact-map').then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(data => {
      if (data?.reasonCode && data.reasonCode == '0000' && data?.data) {
        setContactMap(data.data?.value || '');
      }
    }).catch(err => {
      console.log(err.message);
    });
  }

  useEffect(() => {
    callAPIConfigContactMap();
  }, []);

  return (
    <div className="block-map block">
      <div className="block-map__body" dangerouslySetInnerHTML={{ __html: contactMap || '' }} />
    </div>
  );
}
export default BlockMap;
