/* eslint-disable */
// react
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Currency from '../shared/Currency';
import { Check100Svg } from '../../svg';

// data stubs
//import order from '../../data/accountOrderDetails';
import theme from '../../data/theme';
import { url } from '../../services/utils';
import dummyImage from '../../assets/images/no_products_found.png';
import { orderClearItem } from '../../store/order';

const ShopPageOrderSuccess = props => {
  const { order, orderClearItem } = props;
  const [orderData, setOrderData] = useState();
  const [additionalLines, setAdditionalLines] = useState([]);
  const [items, setItems] = useState([]);

  const mappingItems = paramItems => {
    let tmpItems = paramItems && paramItems.map((item, indx) => {
      var opt = [{
        label: 'SKU',
        value: item?.product?.productSku || ''
      }, {
        label: 'Unit',
        value: item?.priceItem?.priceName || ''
      }];
      const options = (opt || []).map((option, indx) => (
        <li key={indx} className="order-list__options-item">
          <span className="order-list__options-label">
            {option.label}
            {': '}
          </span>
          <span className="order-list__options-value">{option.value}</span>
        </li>
      ));

      return (
        <tr key={indx}>
          <td className="order-list__column-image">
            <div className="product-image">
              <Link to={url.product(item?.product || null)} className="product-image__body">
                <img className="product-image__img" src={item?.product?.productImage?.imageMain || dummyImage} alt="" />
              </Link>
            </div>
          </td>
          <td className="order-list__column-product">
            <Link to={url.product(item)}>{item?.product?.productName || ''}</Link>
            {options.length > 0 && (
              <div className="order-list__options">
                <ul className="order-list__options-list">
                  {options}
                </ul>
              </div>
            )}
          </td>
          <td className="order-list__column-quantity" data-title="Qty:">{item.quantity}</td>
          <td className="order-list__column-total"><Currency value={item.total} /></td>
        </tr>
      );
    });
    setItems(tmpItems);
  }

  useEffect(() => {
    if (orderData) {
      let tmpAdditionalLines = orderData?.extraLines && orderData.extraLines.map((item, indx) => {
        let calShippingForm;

        if (item.type == 'shipping') {
          calShippingForm = (
            <div style={{ margin: '5px 0px', fontSize: '0.9em', fontWeight: 'normal' }}>ThaiPost:
              <span style={{ padding: '0px 10px', color: '#ff5722' }}>{orderData?.orderData?.shippingType ? orderData.orderData.shippingType == 'N' ? 'Register' : orderData.orderData.shippingType : 'Register'}</span>
            </div>
          )
        }

        return (
          <tr key={indx} style={{ verticalAlign: 'top' }}>
            <th className="order-list__column-label" colSpan="3">
              {item?.title || ''}
              {calShippingForm}
            </th>
            <td className="order-list__column-total"><Currency value={item?.price || 0.0} /></td>
          </tr>
        );
      });
      setAdditionalLines(tmpAdditionalLines);
      mappingItems(orderData?.items || []);
    }
  }, [orderData]);

  useEffect(() => {
    if (order?.items && order.items.length > 0) {
      var jsonOrder = Object.assign({}, order);
      setOrderData(jsonOrder);
      // do delete
      orderClearItem();
    }
  }, [order]);

  return (
    <div className="block order-success">
      <Helmet>
        <title>{`Order Success — ${theme.name}`}</title>
      </Helmet>

      <div className="container">
        {orderData?.orderData && (
          <React.Fragment>
          {orderData.orderData?.orderId && (
            <div className="order-success__body">
              <div className="order-success__header">
                <Check100Svg className="order-success__icon" />
                <h1 className="order-success__title">Thank you</h1>
                <div className="order-success__subtitle">Your order has been received</div>
                <div className="order-success__subtitle">The order will send to email:
                  <span style={{ paddingLeft: 10, color: '#ff5722' }}>{orderData?.orderData?.billingAddress?.email || ''}</span>
                </div>
                <div className="order-success__actions">
                  <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
                </div>
              </div>

              <div className="order-success__meta">
                <ul className="order-success__meta-list">
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Order number:</span>
                    <span className="order-success__meta-value">{`${orderData?.orderData?.orderId || ''}`}</span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Created at:</span>
                    <span className="order-success__meta-value">{orderData?.orderData?.orderDate || ''}</span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Total:</span>
                    <span className="order-success__meta-value"><Currency value={orderData?.orderData?.total || 0.0} /></span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Payment method:</span>
                    <span className="order-success__meta-value">
                      {orderData?.orderData?.shippingType ? orderData.orderData.shippingType == 'N' ? 'Register' : orderData.orderData.shippingType : 'Register'}
                    </span>
                  </li>
                </ul>
              </div>

              <div className="card">
                <div className="order-list">
                  <table>
                    <thead className="order-list__header">
                      <tr>
                        <th className="order-list__column-label" colSpan="2">Product</th>
                        <th className="order-list__column-quantity">Qty</th>
                        <th className="order-list__column-total">Total</th>
                      </tr>
                    </thead>
                    <tbody className="order-list__products">
                      {items}
                    </tbody>
                    {additionalLines && additionalLines.length > 0 && (
                      <tbody className="order-list__subtotals">
                        <tr>
                          <th className="order-list__column-label" colSpan="3">Subtotal</th>
                          <td className="order-list__column-total"><Currency value={orderData?.orderData?.subtotal || 0.0} /></td>
                        </tr>
                        {additionalLines}
                      </tbody>
                    )}
                    <tfoot className="order-list__footer">
                      <tr>
                        <th className="order-list__column-label" colSpan="3">Total</th>
                        <td className="order-list__column-total"><Currency value={orderData?.orderData?.total || 0.0} /></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                  <div className="card address-card">
                    <div className="address-card__body">
                      <div className="address-card__badge address-card__badge--muted">
                        Shipping Address
                      </div>
                      <div className="address-card__name">
                        {`${orderData?.orderData?.shippingAddress?.firstName || ''} ${orderData?.orderData?.shippingAddress?.lastName || ''}`}
                      </div>
                      <div className="address-card__row">
                        {orderData?.orderData?.shippingAddress?.country || ''}
                        <br />
                        {`${orderData?.orderData?.shippingAddress?.postcode || ''}, ${orderData?.orderData?.shippingAddress?.city || ''}`}
                        <br />
                        {orderData?.orderData?.shippingAddress?.address || ''}
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{orderData?.orderData?.shippingAddress?.phone || ''}</div>
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{orderData?.orderData?.shippingAddress?.email || ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-12 px-2 mt-sm-0 mt-3">
                  <div className="card address-card">
                    <div className="address-card__body">
                      <div className="address-card__badge address-card__badge--muted">
                        Billing Address
                      </div>
                      <div className="address-card__name">
                        {`${orderData?.orderData?.billingAddress?.firstName || ''} ${orderData?.orderData?.billingAddress?.lastName || ''}`}
                      </div>
                      <div className="address-card__row">
                        {orderData?.orderData?.billingAddress?.country || ''}
                        <br />
                        {`${orderData?.orderData?.billingAddress?.postcode || ''}, ${orderData?.orderData?.billingAddress?.city || ''}`}
                        <br />
                        {orderData?.orderData?.billingAddress?.address || ''}
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{orderData?.orderData?.billingAddress?.phone || ''}</div>
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{orderData?.orderData?.billingAddress?.email || ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {!orderData.orderData?.orderId && (
            <div className="order-success__body">
              <div className="order-success__header">
                <Check100Svg className="order-success__icon" />
                <h1 className="order-success__title">Thank you</h1>
                <div className="order-success__subtitle">Your quotation request has been received</div>
                <div className="order-success__subtitle">
                  The quotation request will send to email: 
                  <span style={{ paddingLeft: 10, color: '#ff5722' }}>{orderData?.orderData?.billingAddress?.email || ''}</span>
                </div>
                <div className="order-success__actions">
                  <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
                </div>
              </div>

              <div className="order-success__meta">
                <ul className="order-success__meta-list">
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Created at:</span>
                    <span className="order-success__meta-value">{orderData?.orderData?.orderDate || ''}</span>
                  </li>
                  <li className="order-success__meta-item">
                    <span className="order-success__meta-title">Total:</span>
                    <span className="order-success__meta-value"><Currency value={orderData?.orderData?.total || 0.0} /></span>
                  </li>
                </ul>
              </div>

              <div className="card">
                <div className="order-list">
                  <table>
                    <thead className="order-list__header">
                      <tr>
                        <th className="order-list__column-label" colSpan="2">Product</th>
                        <th className="order-list__column-quantity">Qty</th>
                        <th className="order-list__column-total">Total</th>
                      </tr>
                    </thead>
                    <tbody className="order-list__products">
                      {items}
                    </tbody>
                    {additionalLines && additionalLines.length > 0 && (
                      <tbody className="order-list__subtotals">
                        <tr>
                          <th className="order-list__column-label" colSpan="3">Subtotal</th>
                          <td className="order-list__column-total"><Currency value={orderData?.orderData?.subtotal || 0.0} /></td>
                        </tr>
                        {additionalLines}
                      </tbody>
                    )}
                    <tfoot className="order-list__footer">
                      <tr>
                        <th className="order-list__column-label" colSpan="3">Total</th>
                        <td className="order-list__column-total"><Currency value={orderData?.orderData?.total || 0.0} /></td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>

              <div className="row mt-3 no-gutters mx-n2">
                <div className="col-sm-6 col-12 px-2">
                  <div className="card address-card">
                    <div className="address-card__body">
                      <div className="address-card__badge address-card__badge--muted">
                        Billing Address
                                  </div>
                      <div className="address-card__name">
                        {`${orderData?.orderData?.billingAddress?.firstName || ''} ${orderData?.orderData?.billingAddress?.lastName || ''}`}
                      </div>
                      <div className="address-card__row">
                        {orderData?.orderData?.billingAddress?.country || ''}
                        <br />
                        {`${orderData?.orderData?.billingAddress?.postcode || ''}, ${orderData?.orderData?.billingAddress?.city || ''}`}
                        <br />
                        {orderData?.orderData?.billingAddress?.address || ''}
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Phone Number</div>
                        <div className="address-card__row-content">{orderData?.orderData?.billingAddress?.phone || ''}</div>
                      </div>
                      <div className="address-card__row">
                        <div className="address-card__row-title">Email Address</div>
                        <div className="address-card__row-content">{orderData?.orderData?.billingAddress?.email || ''}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
        )}
        {!orderData?.orderData && (
        <div className="order-success__body">
          <div className="order-success__header">
            <div className="order-success__subtitle">No order found</div>
            <div className="order-success__actions">
              <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
            </div>
          </div>
        </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  order: state.order,
});

const mapDispatchToProps = {
  orderClearItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageOrderSuccess);