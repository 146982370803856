/* eslint-disable */
// react
import React from 'react';

const ProductTabSpecification = props =>{
  const {data} = props;

  const sections = data && data.map((section, index) => {
    const features = section.details && section.details.map((feature, index) => (
      <div key={index} className="spec__row">
        <div className="spec__name">{feature.specDescTitle || ''}</div>
        <div className="spec__value">{feature.specDescDetail || ''}</div>
      </div>
    ));

    return (
      <div key={index} className="spec__section">
        <h4 className="spec__section-title">{section.productSpecName || ''}</h4>
        {features}
      </div>
    );
  });

  return (
    <div className="spec">
      <h3 className="spec__header">Specification</h3>
      {sections}
    </div>
  );
}

export default ProductTabSpecification;
