/* eslint-disable */
// react
import React,{useEffect,useState} from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import Rating from '../shared/Rating';
import { compareRemoveItem,compareClearItem } from '../../store/compare';
import { Cross12Svg,Check100Svg } from '../../svg';
import { url } from '../../services/utils';
import moment from 'moment';
var validate = require("validate.js");

// data stubs
import PageLoading from '../shared/PageLoading';
import theme from '../../data/theme';

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  },
};

const ShopPageQuotation = props =>{
  const { compare,compareRemoveItem,compareClearItem } = props;
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Quotation Form', url: '' },
  ];

  const [loading,setLoading] = useState(false);
  const [formData,setFormData] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [quotationData,setQuotationData] = useState();

  let content;

  if (compare.length) {
    const itemsList = compare.map((item) => {
      let image;

      if (item?.image) {
        image = (
          <div className="product-image">
            <Link to={url.product(item)} className="product-image__body">
              <img className="product-image__img" src={item?.image || ''} alt="" />
            </Link>
          </div>
        );
      }

      const renderRemoveButton = ({ run, loading }) => {
        const classes = classNames('btn btn-light btn-sm btn-svg-icon', {
          'btn-loading': loading,
        });

        return <button type="button" onClick={run} className={classes} aria-label="Remove"><Cross12Svg /></button>;
      };

      return (
        <tr key={item.id} className="wishlist__row">
          <td className="wishlist__column wishlist__column--image">
            {image}
          </td>
          <td className="wishlist__column wishlist__column--product">
            <Link to={url.product(item)} className="wishlist__product-name">{item.name}</Link>
            {false && (
            <div className="wishlist__product-rating">
              <Rating value={item.rating} />
              <div className="wishlist__product-rating-legend">{`${item.reviews} Reviews`}</div>
            </div>
            )}
          </td>
          <td className="wishlist__column"><Currency value={item.price} /></td>
          <td className="wishlist__column wishlist__column--remove">
            <AsyncAction
            action={() => compareRemoveItem(item.id)}
            render={renderRemoveButton} />
          </td>
        </tr>
      );
    });

    content = (
      <div className="block">
        <div className="container">
          <table className="wishlist">
            <thead className="wishlist__head">
              <tr className="wishlist__row">
                <th className="wishlist__column wishlist__column--image">Image</th>
                <th className="wishlist__column wishlist__column--product">Product</th>
                <th className="wishlist__column">Estimate Price</th>
                <th className="wishlist__column wishlist__column--remove" aria-label="Remove" />
              </tr>
            </thead>
            <tbody className="wishlist__body">
              {itemsList}
            </tbody>
          </table>
        </div>
      </div>
    );
  } else {
    content = (
      <div className="block block-empty">
        <div className="container">
          <div className="block-empty__body">
            <div className="block-empty__message">Your quotation list is empty!</div>
            <div className="block-empty__actions">
              <Link to="/" className="btn btn-primary btn-sm">Continue</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const submitRequestQuotation = () =>{
    var param = {
      billing:{
        firstName: formData.values?.firstName || null,
        lastName: formData.values?.lastName || null,
        companyName: null,
        countryId: null,
        streetAddress: null,
        addressOther: null,
        city: null,
        state: null,
        zipCode: null,
        email: formData.values?.email || null,
        phone: formData.values?.phone || null,
      },
      subTotal: 0,
      total: 0,
      status: 'N',
      notes: null,
      email: formData.values?.email || null,
    };

    if(compare.length>0){
      param.products = [];
      compare.map((item,indx)=>{
        param.products.push({
          productId: item?.id || null,
          priceId: item?.priceId || null,
          qty: 1,
          sort: (indx+1),
          status: true
        });
      });
    }

    setLoading(true);
    fetch(process.env.API_ENDPOINT + 'order/requestQuotation', {
      method: 'post',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(param),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(data => {
      if(data?.reasonCode && data.reasonCode=='0000' && data?.data){
        setQuotationData({
          quotationDate: moment().format('DD/MM/YYYY HH:mm'),
          firstName: formData.values?.firstName || null,
          lastName: formData.values?.lastName || null,
          phone: formData.values?.phone || null,
          email: formData.values?.email || null,
        });
        compareClearItem();
      }
      setLoading(false);
    }).catch((err) => {
      console.log(err.message);
      setLoading(false);
    });
  }

  const handleOnChange = evt =>{
    evt.persist();
    var fieldName = evt.target.name;
    var vl = evt.target.value;
    setFormData(formData => ({
      ...formData,
      values: {
        ...formData.values,
        [fieldName]: vl
      },
      touched: {
        ...formData.touched,
        [fieldName]: true
      }
    }));
  }

  const hasError = field => {
    return formData.touched[field] && formData.errors[field] ? true : false;
  }

  const checkValidate = () =>{
    const errors = validate(formData.values, schema);
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {},
      touched:{
        ...formData.touched,
        firstName: true,
        lastName: true,
        email: true,
      }
    }));
    return errors ? false : true;
  }

  const validateSubmit = evt =>{
    evt.persist();
    if(checkValidate()){
      submitRequestQuotation();
    }
  }

  useEffect(()=>{
    const errors = validate(formData.values, schema);
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  },[formData.values]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Quotation Form — ${theme.name}`}</title>
      </Helmet>
      <PageHeader header="Quotation Form" breadcrumb={breadcrumb} />
      {!quotationData && (
      <React.Fragment>
      {content}
      {compare.length>0 && (
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 col-xl-7">
            <div className="card mb-lg-0">
              <div className="card-body">
                <h3 className="card-title">Contact Information</h3>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="checkout-first-name">First Name&nbsp;<span className="text-danger">*</span></label>
                    <input
                    type="text"
                    id="checkout-first-name"
                    className={'form-control'+(hasError('firstName')?' is-invalid':'')}
                    placeholder="First Name"
                    name={'firstName'}
                    value={formData.values?.firstName || ''}
                    onChange={handleOnChange} />
                    {hasError('firstName') && (
                    <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['firstName'][0] || ''}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="checkout-last-name">Last Name&nbsp;<span className="text-danger">*</span></label>
                    <input
                    type="text"
                    id="checkout-last-name"
                    className={'form-control'+(hasError('lastName')?' is-invalid':'')}
                    placeholder="Last Name"
                    name={'lastName'}
                    value={formData.values?.lastName || ''}
                    onChange={handleOnChange} />
                    {hasError('lastName') && (
                    <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['lastName'][0] || ''}</span>
                    )}
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label htmlFor="checkout-email">Email address&nbsp;<span className="text-danger">*</span></label>
                    <input
                    type="email"
                    className={'form-control'+(hasError('email')?' is-invalid':'')}
                    id="checkout-email"
                    placeholder="Email address"
                    name={'email'}
                    value={formData.values?.email || ''}
                    onChange={handleOnChange} />
                    {hasError('email') && (
                    <span style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['email'][0] || ''}</span>
                    )}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="checkout-phone">Phone</label>
                    <input 
                    type="text" 
                    className="form-control" 
                    id="checkout-phone" 
                    placeholder="Phone"
                    name={'phone'}
                    value={formData.values?.phone || ''}
                    onChange={handleOnChange} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
            <div className="card mb-0">
              <div className="card-body">
                <button onClick={validateSubmit} type="button" className="btn btn-primary btn-xl btn-block">
                Request Quotation
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      </React.Fragment>
      )}
      {quotationData && (
      <div className="container">
        <div className="order-success__body">
          <div className="order-success__header">
            <Check100Svg className="order-success__icon" />
            <h1 className="order-success__title">Thank you</h1>
            <div className="order-success__subtitle">Your quotation request has been received</div>
            <div className="order-success__subtitle">
              The quotation request will send to email: 
              <span style={{ paddingLeft: 10, color: '#ff5722' }}>{quotationData?.email || ''}</span>
            </div>
            <div className="order-success__actions">
              <Link to="/" className="btn btn-xs btn-secondary">Go To Homepage</Link>
            </div>
          </div>

          <div className="order-success__meta">
            <ul className="order-success__meta-list">
              <li className="order-success__meta-item">
                <span className="order-success__meta-title">Created at:</span>
                <span className="order-success__meta-value">{quotationData?.quotationDate || ''}</span>
              </li>
            </ul>
          </div>

          <div className="row mt-3 no-gutters mx-n2">
            <div className="col-sm-6 col-12 px-2">
              <div className="card address-card">
                <div className="address-card__body">
                  <div className="address-card__badge address-card__badge--muted">Contact Data</div>
                  <div className="address-card__name">
                    {`${quotationData?.firstName || ''} ${quotationData?.lastName || ''}`}
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Phone Number</div>
                    <div className="address-card__row-content">{quotationData?.phone || ''}</div>
                  </div>
                  <div className="address-card__row">
                    <div className="address-card__row-title">Email Address</div>
                    <div className="address-card__row-content">{quotationData?.email || ''}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
      <PageLoading
      loading={loading} />
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  compare: state.compare,
});

const mapDispatchToProps = {
  compareRemoveItem,
  compareClearItem,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ShopPageQuotation);
