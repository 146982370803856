/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const BlockBanner = props => {
  const { locale } = props;

  const [content, setContent] = useState();
  const initAPI = paramLocale => {
    fetch(process.env.API_ENDPOINT + 'content/detail', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': paramLocale || 'en'
      },
      body: JSON.stringify({
        contentSlug: 'block-banner'
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(data => {
      if (data.reasonCode && data.reasonCode == '0000' && data?.data) {
        setContent(data.data);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  useEffect(() => {
    initAPI(locale);
  }, [locale]);

  return (
    <div className="block block-banner">
      <div className="container">
        <div style={{position: 'relative',padding: '10px 20px'}}>
          <div
          className="block-banner__image block-banner__image--desktop"
          style={{
            backgroundImage: `url('${content?.contentImageProfile || ''}')`,
            backgroundSize: 'cover',
          }} />
          <div
          className="block-banner__image block-banner__image--mobile"
          style={{
            backgroundImage: `url('${content?.contentImageProfile || ''}')`,
            backgroundSize: 'cover',
          }} />
          <div dangerouslySetInnerHTML={{ __html: content?.contentDetail || '' }} />
        </div>
      </div>
    </div>
  );
}
const mapStateToProps = (state) => ({
  locale: state.locale,
});
export default connect(mapStateToProps)(BlockBanner);
