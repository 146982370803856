/* eslint-disable */
// react
import React, { Fragment } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// application
import { ArrowRoundedLeft6x9Svg } from '../../svg';
import { getCategoryParents, url } from '../../services/utils';

function FilterCategory(props) {
  const { data } = props;

  const categoriesList = data.items.map((category) => {
    const itemClasses = classNames('filter-categories__item', {
      'filter-categories__item--current': data?.value && data.value === category.cateSlug,
    });

    return (
      <Fragment key={category.cateId}>
        <li className={itemClasses}>
          <Link to={url.category(category)}>{category.cateName}</Link>
        </li>
        {category.items.length > 0 && category.items.map((item,indx)=>(
        <li key={indx} className="filter-categories__item filter-categories__item--child">
          <Link to={url.category(item)}>{item.cateName}</Link>
        </li>
        ))}
      </Fragment>
    );
  });

  if (data.value) {
    categoriesList.unshift(
      <li key="[shop]" className="filter-categories__item filter-categories__item--parent">
        <ArrowRoundedLeft6x9Svg className="filter-categories__arrow" />
        <Link to={url.catalog()}>All Products</Link>
      </li>,
    );
  }

  return (
    <div className="filter-categories">
      <ul className="filter-categories__list">
        {categoriesList}
      </ul>
    </div>
  );
}

FilterCategory.propTypes = {
  /**
   * Filter object.
   */
  data: PropTypes.object,
};

export default FilterCategory;
