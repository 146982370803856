/* eslint-disable */
// react
import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// data stubs
import theme from '../../data/theme';

const SitePageContent = props =>{
  const {locale} = props;

  const [content,setContent] = useState();
  const [breadcrumb,setBreadcrumb] = useState([]);

  const callAPIContent = (paramSlug,paramLocale) =>{
    fetch(process.env.API_ENDPOINT + 'content/detail', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': paramLocale || 'en'
      },
      body: JSON.stringify({
        contentSlug: paramSlug || ''
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(data => {
      if (data.reasonCode && data.reasonCode == '0000' && data?.data) {
        setContent(data.data);

        setBreadcrumb([
          { title: 'Home', url: '' },
          { title: data.data?.contentTitle || '', url: '' },
        ]);
      }else{
        props.history.push('/site/not-found');
      }
    }).catch((err) => {
      console.log(err.message);
      props.history.push('/site/not-found');
    });
  }

  useEffect(()=>{
    if(props.match.params?.slug && locale){
      callAPIContent(props.match.params.slug, locale);
    }
  },[props.match.params?.slug,locale]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${content?.contentTitle || ''} — ${theme.name}`}</title>
      </Helmet>

      <PageHeader header={content?.contentTitle || ''} breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          {content?.contentImageProfile?
          <div className="row">
            <div className="col-12 mb-3 text-center">
              <img src={content?.contentImageProfile || ''} style={{maxWidth: '100%'}} />
            </div>
          </div>
          :null}
          <div dangerouslySetInnerHTML={{__html: content?.contentDetail || ''}} />
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(SitePageContent);