/* eslint-disable */
// react
import React,{useState,useEffect} from 'react';
import { connect } from 'react-redux';

// application
import FooterContacts from './FooterContacts';
import FooterLinks from './FooterLinks';
import FooterNewsletter from './FooterNewsletter';
import ToTop from './ToTop';

// data stubs
import theme from '../../data/theme';
import qrImg from '../../assets/images/company-qr.jpg';

const Footer = props =>{
    const {locale} = props

    const [informationLinks,setInformationLinks] = useState([]);
    const [accountLinks,setAccountLinks] = useState([]);
    /*const [accountLinks,setAccountLinks] = useState([
        { title: 'Store Location', url: '' },
        { title: 'Order History', url: '' },
        { title: 'Wish List', url: '' },
        { title: 'Newsletter', url: '' },
        { title: 'Specials', url: '' },
        { title: 'Gift Certificates', url: '' },
        { title: 'Affiliate', url: '' },
    ]);*/

    const callAPIFooterMenu = (locale) => {
        fetch(process.env.API_ENDPOINT + 'content/menu', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'accept-language': locale || 'en'
            },
            body: JSON.stringify({
                menuSlug: 'footer-menu'
            }),
            timeout: process.env.API_TIMEOUT
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
        }).then(data => {
            if (data.reasonCode && data.reasonCode == '0000' && data?.data && data.data.length > 0) {
                var tmpMenu = [];
                data.data && data.data.map(item=>{
                    tmpMenu.push({
                        title: item?.name || '',
                        url: item?.url || ''
                    });
                });
                setInformationLinks(tmpMenu);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    };

    useEffect(()=>{
        if(locale){
            callAPIFooterMenu(locale);
        }
    },[locale]);

    return (
        <div className="site-footer">
            <div className="container">
                <div className="site-footer__widgets">
                    <div className="row">
                        <div className="col-12 col-md-6 col-lg-4">
                            <FooterContacts />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <FooterLinks title="Information" items={informationLinks} />
                        </div>
                        <div className="col-6 col-md-3 col-lg-2">
                            <div className="site-footer__widget footer-links">
                                <h5 className="footer-links__title">QR Code</h5>
                                <div className="footer-links__list">
                                    <div style={{
                                        border: '#000 2px solid',
                                        background: 'url('+qrImg+')',
                                        backgroundPosition: '-2px 1px',
                                        backgroundSize: 'cover',
                                        marginBottom: 10
                                    }}>
                                        <img src={qrImg} style={{width: '100%',opacity: 0}} />
                                    </div>
                                    <div style={{fontSize: '0.9em',wordBreak:'break-word'}} className="text-center">Scan me for more detail of our company.</div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-4">
                            <FooterNewsletter />
                        </div>
                    </div>
                </div>

                <div className="site-footer__bottom">
                    <div className="site-footer__copyright">
                        Powered by
                        {' '}
                        <a href="https://reactjs.org/" rel="noopener noreferrer" target="_blank">React</a>
                        {' '}
                        — Design by
                        {' '}
                        <a href={theme.author.profile_url} target="_blank" rel="noopener noreferrer">
                            {theme.author.name}
                        </a>
                    </div>
                    <div className="site-footer__payments">
                        <img src="images/payments.png" alt="" />
                    </div>
                </div>
            </div>
            <ToTop />
        </div>
    );
}
const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(Footer);