/* eslint-disable */
import {ORDER_ADD_ITEM,ORDER_CLEAR_ITEM} from './orderActionTypes';


function orderAddData(state, items, extraLines, orderData){
  console.log(state);
  return {
    ...state,
    items,
    extraLines,
    orderData
  };
}

function orderClearItem(state) {
  return {};
}

export default function cartReducer(state = {}, action) {
  switch (action.type) {
    case ORDER_ADD_ITEM:
      return orderAddData(state, action.items, action.extraLines, action.orderData);

    case ORDER_CLEAR_ITEM:
      return orderClearItem(state);

    default:
      return state;
  }
}
