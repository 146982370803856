/* eslint-disable */
import { toast } from 'react-toastify';
import { CART_ADD_ITEM, CART_ADD_ITEM_PRICE,CART_REMOVE_ITEM, 
  CART_UPDATE_QUANTITIES,CART_SHIPPING_TYPE,CART_SHIPPING_PRICE,CART_CLEAR_ITEM } from './cartActionTypes';

export function cartAddItemSuccess(product, options = [], quantity = 1) {
  toast.success(`Product "${product.name}" added to cart!`);

  return {
    type: CART_ADD_ITEM,
    product,
    options,
    quantity,
  };
}

export function cartAddItemPricesSuccess(product, productPrice, options = [], quantity = 1) {
  toast.success(`Product "${product.productName} - ${productPrice.priceName}" added to cart!`);

  return {
    type: CART_ADD_ITEM_PRICE,
    product,
    productPrice,
    options,
    quantity,
  };
}

export function cartRemoveItemSuccess(itemId) {
  return {
    type: CART_REMOVE_ITEM,
    itemId,
  };
}

export function cartClearItem() {
  return {
    type: CART_CLEAR_ITEM
  };
}

export function cartUpdateQuantitiesSuccess(quantities) {
  return {
    type: CART_UPDATE_QUANTITIES,
    quantities,
  };
}

export function changeShippingType(shippingType){
  return {
    type: CART_SHIPPING_TYPE,
    shippingType
  }
}

export function updateShippingPrice(shippingPrice){
  return {
    type: CART_SHIPPING_PRICE,
    shippingPrice
  }
}

export function cartAddItem(product, options = [], quantity = 1) {
  // sending request to server, timeout is used as a stub
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(cartAddItemSuccess(product, options, quantity));
        resolve();
      }, 500);
    })
  );
}

export function cartAddItemPrices(product, productPrice, options = [], quantity = 1) {
  // sending request to server, timeout is used as a stub
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(cartAddItemPricesSuccess(product, productPrice, options, quantity));
        resolve();
      }, 500);
    })
  );
}

export function cartRemoveItem(itemId) {
  // sending request to server, timeout is used as a stub
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(cartRemoveItemSuccess(itemId));
        resolve();
      }, 500);
    })
  );
}

export function cartUpdateQuantities(quantities) {
  // sending request to server, timeout is used as a stub
  return (dispatch) => (
    new Promise((resolve) => {
      setTimeout(() => {
        dispatch(cartUpdateQuantitiesSuccess(quantities));
        resolve();
      }, 500);
    })
  );
}
