/* eslint-disable */
// react
import React from 'react';

const ProductTabDescription = props =>{
  const {data} = props;

  return (
    <div className="typography" dangerouslySetInnerHTML={{__html: data}} />
  );
}

export default ProductTabDescription;
