/* eslint-disable */
// react
import React, {useState,useEffect} from 'react';
import moment from 'moment';

// third-party
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

// application
import Collapse from '../shared/Collapse';
import Currency from '../shared/Currency';
import PageHeader from '../shared/PageHeader';
import { Check9x7Svg } from '../../svg';

// data stubs
import payments from '../../data/shopPayments';
import theme from '../../data/theme';
import Address from '../address/Address';
import {updateShippingPrice,cartClearItem} from '../../store/cart';
import {orderAddData,orderClearItem} from '../../store/order';
import PageLoading from '../shared/PageLoading';
import {APICalculate} from '../../api/thaipost';
import {APIConfig} from '../../api/config';

var validate = require("validate.js");

const schema = {
  firstName: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  lastName: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  countryId: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  streetAddress: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  city: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  state: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  postcode: {
    presence: { allowEmpty: false, message: 'is required' }
  },
  email: {
    presence: { allowEmpty: false, message: 'is required' },
    email: true
  }
}

const bankTableStyle = {
  border: '#bbbbbb 1px solid',
  width: '100%',
  borderCollapse: 'collapse',
  margin: '5px 0px',
  fontSize: '0.9em',
};
const bankTh1Style = {
  backgroundColor: '#e4e4e4',
  borderBottom: '#bbbbbb 1px solid',
  borderRight: '#bbbbbb 1px solid',
  width: '40px',
  boxSizing: 'border-box',

};
const bankTh2Style = {
  backgroundColor: '#e4e4e4',
  borderBottom: '#bbbbbb 1px solid',
  padding: '3px 5px',
};

const ShopPageCheckout = props =>{
  const {cart,updateShippingPrice,cartClearItem,orderAddData,orderClearItem} = props;

  const [breadcrumb] = useState([
    { title: 'Home', url: '' },
    { title: 'Shopping Cart', url: '/shop/cart' },
    { title: 'Checkout', url: '' },
  ]);

  const [loading,setLoading] = useState(false);
  const [payment,setPayment] = useState('bank');
  const [currentPayments] = useState(payments);
  const [formData,setFormData] = useState({
    isValid: false,
    values: {
      terms: false
    },
    touched: {},
    errors: {}
  });
  const [billingAddress,setBillingAddress] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [shippingAddress,setShippingAddress] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });
  const [isWeight,setIsWeight] = useState(true);
  const [country,setCountry] = useState([]);
  const [bankTable,setBankTable] = useState();

  const handlePaymentChange = (event) => {
    if (event.target.checked) {
      setPayment(event.target.value);
    }
  };

  const handleBillingChange = evt =>{
    evt.persist();
    var vl = evt.target.value;
    var fieldName = evt.target.name;

    setBillingAddress(billingAddress=>({
      ...billingAddress,
      values:{
        ...billingAddress.values,
        [fieldName]: vl
      },
      touched:{
        ...billingAddress.touched,
        [fieldName]: true
      }
    }));
  }

  const handleBillingFormValidate = errors =>{
    setBillingAddress(billingAddress => ({
      ...billingAddress,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }

  const callAPIBankConfig = () =>{
    APIConfig('bank-account').then(resp=>{
      if(resp.status==200){
        return resp.json();
      }
    }).then(data=>{
      if(data?.data){
        var jsonBank = null;
        try{
          jsonBank = JSON.parse(data.data?.value || ''); 
        }catch(err){}
        if(null!=jsonBank){
          setBankTable(
            <table cellPadding="0" cellSpacing="0"
              style={bankTableStyle}>
              <thead>
                <tr>
                  <th style={bankTh1Style}>&nbsp;</th>
                  <th style={bankTh2Style}>Merchant Bank</th>
                </tr>
              </thead>
              <tbody>
                {jsonBank.map((item,key)=>(
                <tr style={{ verticalAlign: 'middle' }} key={key}>
                  <td style={{
                    borderBottom: '#bbbbbb 1px solid', borderRight: '#bbbbbb 1px solid',
                    textAlign: 'center', padding: '5px 0px'
                  }}><img src={item?.logo || ''} style={{ width: '30px' }} /></td>
                  <td style={{ borderBottom: '#bbbbbb 1px solid', textAlign: 'left', padding: 5 }}>
                    <div style={{ color: '#FF5722' }}>{item?.bankName || ''}</div>
                    <div>Name: {item?.transfereeName || ''}</div>
                    <div>Acct: {item?.transfereeAcct || ''}</div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          );
        }
      }
    }).catch(err=>{
      console.log(err.message);
    });
  }

  const callAPICountry = () =>{
    fetch(process.env.API_ENDPOINT + 'config/getCountry', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json'
      },
      timeout: process.env.API_TIMEOUT
    }).then(resp=>{
      if(resp.status==200){
        return resp.json();
      }
    }).then(data=>{
      setCountry(data);
    }).catch(err=>{
      console.log(err.message);
    });
  }

  const handleShippingChange = evt =>{
    evt.persist();
    var vl = evt.target.value;
    var fieldName = evt.target.name;

    setShippingAddress(shippingAddress=>({
      ...shippingAddress,
      values:{
        ...shippingAddress.values,
        [fieldName]: vl
      },
      touched:{
        ...shippingAddress.touched,
        [fieldName]: true
      }
    }));
  }

  const handleShippingFormValidate = errors =>{
    setShippingAddress(shippingAddress => ({
      ...shippingAddress,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  }

  const submitPlaceOrder = () =>{
    var isBillingValidate = checkBillingValidate();
    var isShippingValidate = true;
    var isFormDataValidate = checkFormDataValidate();
    if(formData.values?.differentAddr){
      isShippingValidate = checkShippingValidate();
    }

    if(isBillingValidate && isShippingValidate && isFormDataValidate){
      var orderParam = {
        billing:{
          firstName: billingAddress.values?.firstName || null,
          lastName: billingAddress.values?.lastName || null,
          companyName: billingAddress.values?.companyName || null,
          countryId: billingAddress.values?.countryId || null,
          streetAddress: billingAddress.values?.streetAddress || null,
          addressOther: billingAddress.values?.optAddress || null,
          city: billingAddress.values?.city || null,
          state: billingAddress.values?.state || null,
          zipCode: billingAddress.values?.postcode || null,
          email: billingAddress.values?.email || null,
          phone: billingAddress.values?.phone || null
        },
        subTotal: cart?.subtotal || 0,
        total: cart?.total || 0,
        status: 'N',
        notes: formData.values?.comment || null,
        email: billingAddress.values?.email || null,
        shippingType: cart?.shippingType || null,
      };
      if(formData.values?.differentAddr){
        orderParam.shipping = {
          firstName: shippingAddress.values?.firstName || null,
          lastName: shippingAddress.values?.lastName || null,
          companyName: shippingAddress.values?.companyName || null,
          countryId: shippingAddress.values?.countryId || null,
          streetAddress: shippingAddress.values?.streetAddress || null,
          addressOther: shippingAddress.values?.optAddress || null,
          city: shippingAddress.values?.city || null,
          state: shippingAddress.values?.state || null,
          zipCode: shippingAddress.values?.postcode || null,
          email: shippingAddress.values?.email || null,
          phone: shippingAddress.values?.phone || null
        }
      }else{
        orderParam.shipping = {
          firstName: billingAddress.values?.firstName || null,
          lastName: billingAddress.values?.lastName || null,
          companyName: billingAddress.values?.companyName || null,
          countryId: billingAddress.values?.countryId || null,
          streetAddress: billingAddress.values?.streetAddress || null,
          addressOther: billingAddress.values?.optAddress || null,
          city: billingAddress.values?.city || null,
          state: billingAddress.values?.state || null,
          zipCode: billingAddress.values?.postcode || null,
          email: billingAddress.values?.email || null,
          phone: billingAddress.values?.phone || null
        }
      }

      if(cart?.items && cart.items.length>0){
        orderParam.products = [];
        cart.items.map((item,indx)=>{
          orderParam.products.push({
            productId: item?.product?.productId || null,
            priceId: item?.priceItem?.priceId || null,
            qty: item?.quantity || 0,
            sort: (indx+1),
            status: true
          });
        });
      }

      setLoading(true);
      fetch(process.env.API_ENDPOINT + 'order/saveOrder', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orderParam),
        timeout: process.env.API_TIMEOUT
      }).then(resp => {
        if (resp.status == 200) {
          return resp.json();
        }
      }).then(data => {
        setLoading(false);
        if(data?.reasonCode && data.reasonCode=='0000' && data?.data){
          var billingCountry = "";
          var shippingCountry = "";

          country && country.map(item=>{
            if(billingAddress.values?.countryId==item.id){
              billingCountry = item.nicename
            }
            if(shippingAddress.values?.countryId==item.id){
              shippingCountry = item.nicename
            }
          });

          var jsonBillAddr = {
            firstName: billingAddress.values?.firstName || null,
            lastName: billingAddress.values?.lastName || null,
            country: billingCountry,
            postcode: billingAddress.values?.postcode || null,
            city: billingAddress.values?.city || null,
            address: billingAddress.values?.streetAddress || null,
            phone: billingAddress.values?.phone || null,
            email: billingAddress.values?.email || null
          }

          var orderData = {
            orderId: data.data,
            orderDate: moment().format('DD/MM/YYYY HH:mm'),
            shippingType: cart?.shippingType || 'N',
            subtotal: cart?.subtotal || 0.0,
            total: cart?.total || 0.0,
            billingAddress: jsonBillAddr
          }

          if(formData.values?.differentAddr){
            orderData.shippingAddress = {
              firstName: shippingAddress.values?.firstName || null,
              lastName: shippingAddress.values?.lastName || null,
              country: shippingCountry,
              postcode: shippingAddress.values?.postcode || null,
              city: shippingAddress.values?.city || null,
              address: shippingAddress.values?.streetAddress || null,
              phone: shippingAddress.values?.phone || null,
              email: shippingAddress.values?.email || null
            }
          }else{
            orderData.shippingAddress = jsonBillAddr
          }

          orderAddData(cart?.items || [],cart?.extraLines || [],orderData);
          props.history.push('/shop/checkout/success');
          cartClearItem();
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
    }
  }

  const submitRequestQuotation = () =>{
    var isBillingValidate = checkBillingValidate();
    var isFormDataValidate = checkFormDataValidate();

    if(isBillingValidate && isFormDataValidate){
      var orderParam = {
        billing:{
          firstName: billingAddress.values?.firstName || null,
          lastName: billingAddress.values?.lastName || null,
          companyName: billingAddress.values?.companyName || null,
          countryId: billingAddress.values?.countryId || null,
          streetAddress: billingAddress.values?.streetAddress || null,
          addressOther: billingAddress.values?.optAddress || null,
          city: billingAddress.values?.city || null,
          state: billingAddress.values?.state || null,
          zipCode: billingAddress.values?.postcode || null,
          email: billingAddress.values?.email || null,
          phone: billingAddress.values?.phone || null
        },
        subTotal: cart?.subtotal || 0,
        total: cart?.total || 0,
        status: 'N',
        notes: formData.values?.comment || null,
        email: billingAddress.values?.email || null,
      };

      if(cart?.items && cart.items.length>0){
        orderParam.products = [];
        cart.items.map((item,indx)=>{
          orderParam.products.push({
            productId: item?.product?.productId || null,
            priceId: item?.priceItem?.priceId || null,
            qty: item?.quantity || 0,
            sort: (indx+1),
            status: true
          });
        });
      }

      setLoading(true);
      fetch(process.env.API_ENDPOINT + 'order/requestQuotation', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(orderParam),
        timeout: process.env.API_TIMEOUT
      }).then(resp => {
        if (resp.status == 200) {
          return resp.json();
        }
      }).then(data => {
        setLoading(false);
        if(data?.reasonCode && data.reasonCode=='0000' && data?.data){
          var billingCountry = "";
          var shippingCountry = "";

          country && country.map(item=>{
            if(billingAddress.values?.countryId==item.id){
              billingCountry = item.nicename
            }
            if(shippingAddress.values?.countryId==item.id){
              shippingCountry = item.nicename
            }
          });

          var jsonBillAddr = {
            firstName: billingAddress.values?.firstName || null,
            lastName: billingAddress.values?.lastName || null,
            country: billingCountry,
            postcode: billingAddress.values?.postcode || null,
            city: billingAddress.values?.city || null,
            address: billingAddress.values?.streetAddress || null,
            phone: billingAddress.values?.phone || null,
            email: billingAddress.values?.email || null
          }

          var orderData = {
            orderDate: moment().format('DD/MM/YYYY HH:mm'),
            shippingType: cart?.shippingType || 'N',
            subtotal: cart?.subtotal || 0.0,
            total: cart?.total || 0.0,
            billingAddress: jsonBillAddr
          }

          orderAddData(cart?.items || [],cart?.extraLines || [],orderData);
          props.history.push('/shop/checkout/success');
          cartClearItem();
        }
      }).catch((err) => {
        setLoading(false);
        console.log(err.message);
      });
    }
  }

  const handleSubmit = evt =>{
    evt.persist();
    if(isWeight==true){
      submitPlaceOrder();
    }else{
      submitRequestQuotation();
    }
  }

  const renderCart = () =>{
    const items = cart && cart?.items.map((item) => (
      <tr key={item.id}>
        <td>{`${item.product.productName} ${item.priceItem?'('+item.priceItem.priceName+')':''} × ${item.quantity}`}</td>
        <td><Currency value={item.total} /></td>
      </tr>
    ));

    return (
      <table className="checkout__totals">
        <thead className="checkout__totals-header">
          <tr>
            <th>Product</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody className="checkout__totals-products">
          {items}
        </tbody>
        {renderTotals()}
        <tfoot className="checkout__totals-footer">
          <tr>
            <th>Total</th>
            <td><Currency value={cart.total} /></td>
          </tr>
        </tfoot>
      </table>
    );
  }

  const renderTotals = () =>{
    if (cart?.extraLines.length <= 0) {
      return null;
    }

    const extraLines = cart?.extraLines && cart.extraLines.map((extraLine, index) => {
      let isDisplay = true;
      let calShippingForm;

      if(extraLine.type == 'shipping'){
        if(isWeight==true){
          calShippingForm = (
            <div style={{margin: '5px 0px',fontSize: '0.9em',fontWeight: 'normal'}}>ThaiPost:
              <span style={{padding: '0px 10px',color: '#ff5722'}}>{cart?.shippingType?cart.shippingType=='N'?'Register':cart.shippingType:'Register'}</span>
            </div>
          );
        }else{
          isDisplay = false;
        }
      }

      return (
        isDisplay==true?
        <tr key={index} style={{verticalAlign: 'top'}}>
          <th>{extraLine.title}{calShippingForm}</th>
          <td><Currency value={extraLine.price} /></td>
        </tr>
        :null
      );
    });

    return (
      <React.Fragment>
        <tbody className="checkout__totals-subtotals">
          <tr>
            <th>Subtotal</th>
            <td><Currency value={cart.subtotal} /></td>
          </tr>
          {extraLines}
        </tbody>
      </React.Fragment>
    );
  }

  const renderPaymentsList = () =>{
    const currentPayment = payment;
    const renderPayments = currentPayments && currentPayments.map((payment) => {
      const renderPayment = ({ setItemRef, setContentRef }) =>{
        return (
          <li className="payment-methods__item" ref={setItemRef}>
            <label className="payment-methods__item-header">
              <span className="payment-methods__item-radio input-radio">
                <span className="input-radio__body">
                  <input
                  type="radio"
                  className="input-radio__input"
                  name="checkout_payment_method"
                  value={payment.key}
                  checked={currentPayment === payment.key}
                  onChange={handlePaymentChange} />
                  <span className="input-radio__circle" />
                </span>
              </span>
              <span className="payment-methods__item-title">{payment.title}</span>
            </label>
            {payment?.key && payment.key!='bank' && (
            <div className="payment-methods__item-container" ref={setContentRef}>
              <div className="payment-methods__item-description text-muted">
                {payment?.description || ''}
              </div>
            </div>
            )}
            {payment?.key && payment.key=='bank' && (
            <div className="payment-methods__item-container" style={{height: 'auto'}}>
              <div className="payment-methods__item-description text-muted">
                {bankTable || ''}
              </div>
            </div>
            )}
          </li>
        );
      };

      return (
        <Collapse
        key={payment.key}
        open={currentPayment === payment.key}
        toggleClass="payment-methods__item--active"
        render={renderPayment} />
      );
    });

    return (
      <div className="payment-methods">
        <ul className="payment-methods__list">
          {renderPayments}
        </ul>
      </div>
    );
  }

  const checkBillingValidate = () =>{
    const errors = validate(billingAddress.values, schema);
    setBillingAddress(billingAddress=>({
      ...billingAddress,
      isValid: errors ? false : true,
      errors: errors || {},
      touched:{
        firstName: true,
        lastName: true,
        countryId: true,
        streetAddress: true,
        city: true,
        state: true,
        postcode: true,
        email: true
      }
    }));

    return errors ? false : true;
  }

  const checkShippingValidate = () =>{
    const errors = validate(shippingAddress.values, schema);
    setShippingAddress(shippingAddress=>({
      ...shippingAddress,
      isValid: errors ? false : true,
      errors: errors || {},
      touched:{
        firstName: true,
        lastName: true,
        countryId: true,
        streetAddress: true,
        city: true,
        state: true,
        postcode: true,
        email: true
      }
    }));

    return errors ? false : true;
  }

  const checkFormDataValidate = () =>{
    const errors = validate(formData.values, {
      terms: {
        inclusion: {
          within: [true],
          message: "^Please check accept terms and conditions"
        }
      }
    });
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {},
      touched:{
        terms: true
      }
    }));
    return errors ? false : true;
  }

  const hasError = field => {
    return formData.touched[field] && formData.errors[field] ? true : false;
  }

  useEffect(()=>{
    const errors = validate(formData.values, {
      terms: {
        inclusion: {
          within: [true],
          message: "^Please check accept terms and conditions"
        }
      }
    });
    setFormData(formData => ({
      ...formData,
      isValid: errors ? false : true,
      errors: errors || {}
    }));
  },[formData.values]);

  const calculateShipping = paramCart =>{
    var totalWeight = 0;
    var paramIsWeight = true;
    var shippingLine = null;

    paramCart?.items && paramCart.items.map(item=>{
      if(item?.priceItem){
        if(item.priceItem?.priceWeight<1){
          paramIsWeight = false;
        }
        totalWeight += (item.priceItem?.priceWeight * item?.quantity);
      }
    });

    paramCart?.extraLines && paramCart.extraLines.map(item=>{
      if(item.type == 'shipping'){
        shippingLine = item;
      }
    });

    setIsWeight(paramIsWeight);
    if(paramIsWeight && totalWeight>0){
      APICalculate(totalWeight).then(resp => {
        if(resp.status==200){
          return resp.json();
        }
      }).then(data => {
        if(data.reasonCode && data.reasonCode=='0000' && data?.data && data.data.length>0){
          let shippingType = paramCart?.shippingType || 'N';
          var arrTmpPrice = [];
          data.data && data.data.map(item=>{
            if(item?.type==shippingType){
              arrTmpPrice.push(item?.price || 0);
            }
          });

          if(arrTmpPrice.length>0){
            arrTmpPrice.sort(function(a, b){return a - b});
            if(shippingLine && shippingLine.price!=arrTmpPrice[0]){
              updateShippingPrice(arrTmpPrice[0]);
            }
          }else{
            updateShippingPrice(0);
          }
        }else{
          updateShippingPrice(0);
        }
      }).catch((err) => {
        console.log(err.message);
      });
    }
  }

  useEffect(()=>{
    if(cart?.items && cart.items.length>0){
      calculateShipping(cart);
      orderClearItem();
    }else{
      props.history.push('/');
    }
  },[cart]);

  useEffect(()=>{
    callAPIBankConfig();
    callAPICountry();
  },[]);

  return (
    <React.Fragment>
        <Helmet>
          <title>{`Checkout — ${theme.name}`}</title>
        </Helmet>

        <PageHeader header="Checkout" breadcrumb={breadcrumb} />

        <div className="checkout block">
          <div className="container">
            <div className="row">
              {false && (
              <div className="col-12 mb-3">
                <div className="alert alert-primary alert-lg">
                  Returning customer?{' '}
                  <Link to="/account/login">Click here to login</Link>
                </div>
              </div>
              )}

              <div className="col-12 col-lg-6 col-xl-7">
                <div className="card mb-lg-0">
                  <div className="card-body">
                    <Address
                    title={'Billing details'}
                    form={billingAddress}
                    data={billingAddress?.values || null}
                    country={country || []}
                    handleOnChange={handleBillingChange}
                    handleFormValidate={handleBillingFormValidate}
                    schema={schema} />
                    {false?<div className="form-group">
                      <div className="form-check">
                        <span className="form-check-input input-check">
                          <span className="input-check__body">
                            <input className="input-check__input" type="checkbox" id="checkout-create-account" />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                          </span>
                        </span>
                        <label className="form-check-label" htmlFor="checkout-create-account">
                          Create an account?</label>
                      </div>
                    </div>:null}
                  </div>
                  {isWeight==true?
                  <React.Fragment>
                    <div className="card-divider" />
                    <div className="card-body">
                      <h3 className="card-title">Shipping Details</h3>

                      <div className="form-group">
                        <div className="form-check">
                          <span className="form-check-input input-check">
                            <span className="input-check__body">
                              <input 
                              className="input-check__input" 
                              type="checkbox" 
                              id="checkout-different-address"
                              checked={formData.values?.differentAddr || false}
                              onChange={e=>{
                                e.persist();
                                setFormData(formData=>({
                                  ...formData,
                                  values:{
                                    ...formData.values,
                                    differentAddr: e.target.checked
                                  },
                                  touched:{
                                    ...formData.touched,
                                    differentAddr: true
                                  }
                                }));
                              }} />
                              <span className="input-check__box" />
                              <Check9x7Svg className="input-check__icon" />
                            </span>
                          </span>
                          <label className="form-check-label" htmlFor="checkout-different-address">
                            Ship to a different address?
                          </label>
                        </div>
                      </div>

                      {formData.values?.differentAddr?
                      <Address
                      form={shippingAddress}
                      data={shippingAddress?.values || null}
                      country={country || []}
                      handleOnChange={handleShippingChange}
                      handleFormValidate={handleShippingFormValidate}
                      schema={schema} />
                      :null}

                      <div className="form-group">
                        <label htmlFor="checkout-comment">
                          Order notes{' '}
                          <span className="text-muted">(Optional)</span>
                        </label>
                        <textarea 
                        id="checkout-comment" 
                        className="form-control" 
                        rows="4"
                        value={formData.values?.comment || ''}
                        onChange={e=>{
                          e.persist();
                          setFormData(formData=>({
                          ...formData,
                          values:{
                            ...formData.values,
                            comment: e.target.value
                          },
                          touched:{
                            ...formData.touched,
                            comment: true
                          }
                        }))}} />
                      </div>
                    </div>
                  </React.Fragment>:null}
                </div>
              </div>

              <div className="col-12 col-lg-6 col-xl-5 mt-4 mt-lg-0">
                <div className="card mb-0">
                  <div className="card-body">
                    <h3 className="card-title">Your Order</h3>

                    {cart?renderCart():null}

                    {isWeight==true?renderPaymentsList():null}

                    <div className="checkout__agree form-group">
                      <div className="form-check">
                        <span className="form-check-input input-check">
                          <span className="input-check__body">
                            <input 
                            className="input-check__input" 
                            type="checkbox" 
                            id="checkout-terms"
                            checked={formData.values?.terms || false}
                            onChange={e=>{
                              e.persist();
                              setFormData(formData=>({
                                ...formData,
                                values:{
                                  ...formData.values,
                                  terms: e.target.checked
                                },
                                touched:{
                                  ...formData.touched,
                                  terms: true
                                }
                              }));
                            }} />
                            <span className="input-check__box" />
                            <Check9x7Svg className="input-check__icon" />
                          </span>
                        </span>
                        <label className="form-check-label" htmlFor="checkout-terms">
                          I have read and agree to the website&nbsp;
                          <Link to="site/terms">terms and conditions</Link>
                          {hasError('terms')?
                          <div style={{fontSize: '0.8em'}} className="text-danger">{formData?.errors['terms'][0] || ''}</div>
                          :null}
                        </label>
                      </div>
                    </div>

                    <button onClick={handleSubmit} type="button" className="btn btn-primary btn-xl btn-block">
                      {isWeight==true?'Place Order':'Request Quotation'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <PageLoading
        loading={loading} />
      </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
});

const mapDispatchToProps = {
  updateShippingPrice,
  cartClearItem,
  orderAddData,
  orderClearItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopPageCheckout);
