/* eslint-disable */
// react
import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';

// third-party
import { Link } from 'react-router-dom';

// application
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedRight6x9Svg } from '../../svg';

const DepartmentsLinks = (props) => {

  const { locale } = props;
  const [headerCategory,setHeaderCategory] = useState();

  useEffect(()=>{
    if(locale){
      getAPICategory(locale);
    }
  },[locale])

  const departments = null;
  const linksList = headerCategory && headerCategory.map((department, index) => {
    let arrow = null;
    let submenu = null;
    let itemClass = '';

    if (department.submenu) {
      arrow = <ArrowRoundedRight6x9Svg className="departments__link-arrow" />;
    }

    if (department.submenu && department.submenu.type === 'menu') {
      itemClass = 'departments__item--menu';
      submenu = (
        <div className="departments__menu">
          <Menu items={department.submenu.menu} />
        </div>
      );
    }

    if (department.submenu && department.submenu.type === 'megamenu') {
      submenu = (
        <div className={`departments__megamenu departments__megamenu--${department.submenu.menu.size}`}>
          <Megamenu menu={department.submenu.menu} location="department" />
        </div>
      );
    }

    return (
      <li key={index} className={`departments__item ${itemClass}`}>
        <Link to={department.url}>
          {department.title}
          {arrow}
        </Link>
        {submenu}
      </li>
    );
  });

  const getAPICategory = paramLocale =>{
    fetch(process.env.API_ENDPOINT + "product/category/list", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': paramLocale
      },
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data => {
      if(data.reasonCode && data.reasonCode=='0000'){
        var tmpHeaderCategory = [];
        data.data && data.data.map((v,k)=>{
          var tmp = {
            title: v.cateName,
            url: `/shop/catalog/${v?.cateSlug || ''}`,
            submenu: null
          };

          if(v.items && v.items.length > 0){
            var tmpMenus = [];
            v.items && v.items.map((v2,k2)=>{
              tmpMenus.push({
                title: v2.cateName,
                url: `/shop/catalog/${v2.cateSlug || ''}`
              })
            });
            var tmpSubmenu = {
              type: 'menu',
              menu: tmpMenus
            };
            tmp.submenu = tmpSubmenu;
            tmp.url = '';
          }

          tmpHeaderCategory.push(tmp);
        });
        setHeaderCategory(tmpHeaderCategory);
      }else{
        setHeaderCategory(null);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  return (
    <ul className="departments__links">
      {linksList}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(DepartmentsLinks);
