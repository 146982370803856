export default {
    name: 'P.C.MULTIINTERNATIONAL',
    fullName: 'P.C.MULTIINTERNATIONAL - eCommerce Store',
    url: 'https://www.pcmultiinter.com',
    author: {
        name: 'Administrator',
        profile_url: 'https://www.pcmultiinter.com',
    },
    contacts: {
        address: '99/9 Moo.5 Khokkham Mueang samutsakorn 74000',
        email: 'marketing_01@pcgroupe.co',
        phone: '(+66) 98-329-4229',
        openHour: 'Mon-Sat (24 hr.)',
    },
};
