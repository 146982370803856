/* eslint-disable */
// react
import React,{useEffect,useState} from 'react';
import { connect } from 'react-redux';

// third-party
import { Helmet } from 'react-helmet-async';

// application
import PageHeader from '../shared/PageHeader';

// blocks
import BlockMap from '../blocks/BlockMap';

// data stubs
import theme from '../../data/theme';

const SitePageContactUs = props =>{
  const {locale} = props;

  const [content,setContent] = useState();
  const breadcrumb = [
    { title: 'Home', url: '' },
    { title: 'Contact Us', url: '' },
  ];

  const callAPIContent = (paramLocale) =>{
    fetch(process.env.API_ENDPOINT + 'content/detail', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': paramLocale || 'en'
      },
      body: JSON.stringify({
        contentSlug: 'contact-us'
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if (resp.status == 200) {
        return resp.json();
      }
    }).then(data => {
      if (data.reasonCode && data.reasonCode == '0000' && data?.data) {
        setContent(data.data);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  }

  useEffect(()=>{
    if(locale){
      callAPIContent(locale);
    }
  },[locale]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contact Us — ${theme.name}`}</title>
      </Helmet>

      <BlockMap />

      <PageHeader header="Contact Us" breadcrumb={breadcrumb} />

      <div className="block">
        <div className="container">
          <div className="card mb-0">
            <div className="card-body contact-us">
              <div className="contact-us__container">
                <div className="row">
                  <div className="col-12 col-lg-6 pb-4 pb-lg-0">
                    <div dangerouslySetInnerHTML={{__html: content?.contentDetail || ''}} />
                  </div>

                  <div className="col-12 col-lg-6">
                    <h4 className="contact-us__header card-title">Leave us a Message</h4>

                    <form>
                      <div className="form-row">
                        <div className="form-group col-md-6">
                          <label htmlFor="form-name">Your Name</label>
                          <input type="text" id="form-name" className="form-control" placeholder="Your Name" />
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="form-email">Email</label>
                          <input
                            type="email"
                            id="form-email"
                            className="form-control"
                            placeholder="Email Address"
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <label htmlFor="form-subject">Subject</label>
                        <input type="text" id="form-subject" className="form-control" placeholder="Subject" />
                      </div>
                      <div className="form-group">
                        <label htmlFor="form-message">Message</label>
                        <textarea id="form-message" className="form-control" rows="4" />
                      </div>
                      <button type="submit" className="btn btn-primary">Send Message</button>
                    </form>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(SitePageContactUs);