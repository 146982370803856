/* eslint-disable */
import {ORDER_ADD_ITEM,ORDER_CLEAR_ITEM} from './orderActionTypes';

export function orderAddData(items = [],extraLines=[],orderData={}){
  return {
    type: ORDER_ADD_ITEM,
    items,
    extraLines,
    orderData
  }
}

export function orderClearItem(){
  return {
    type: ORDER_CLEAR_ITEM
  }
}
