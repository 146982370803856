/* eslint-disable */
import React,{useEffect} from 'react';
var validate = require("validate.js");

const Address = props =>{
  const {form,data,handleOnChange,handleFormValidate,schema,title,country} = props;

  const hasError = field => {
    return form.touched[field] && form.errors[field] ? true : false;
  }

  useEffect(()=>{
    const errors = validate(data, schema);
    handleFormValidate(errors);
  },[data]);

  return (
    <>
      {title?
      <h3 className="card-title">{title || ''}</h3>
      :null}
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="checkout-first-name">First Name&nbsp;<span className="text-danger">*</span></label>
          <input
          type="text"
          className={'form-control'+(hasError('firstName')?' is-invalid':'')}
          id="checkout-first-name"
          placeholder="First Name"
          name={'firstName'}
          value={data?.firstName || ''}
          onChange={handleOnChange} />
          {hasError('firstName') && (
          <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['firstName'][0] || ''}</span>
          )}
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="checkout-last-name">Last Name&nbsp;<span className="text-danger">*</span></label>
          <input
          type="text"
          className={'form-control'+(hasError('lastName')?' is-invalid':'')}
          id="checkout-last-name"
          placeholder="Last Name"
          name={'lastName'}
          value={data?.lastName || ''}
          onChange={handleOnChange} />
          {hasError('lastName') && (
          <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['lastName'][0] || ''}</span>
          )}
        </div>
      </div>

      <div className="form-group">
        <label htmlFor="checkout-company-name">
          Company Name{' '}
          <span className="text-muted">(Optional)</span>
        </label>
        <input
        type="text"
        className="form-control"
        id="checkout-company-name"
        placeholder="Company Name"
        name={'companyName'}
        value={data?.companyName || ''}
        onChange={handleOnChange} />
      </div>
      <div className="form-group">
        <label htmlFor="checkout-country">Country&nbsp;<span className="text-danger">*</span></label>
        <select 
        id="checkout-country" 
        className={'form-control'+(hasError('countryId')?' is-invalid':'')}
        name={'countryId'}
        value={data?.countryId || ''}
        onChange={handleOnChange}>
          <option value={''}>Select a country...</option>
          {country && country.map((item,indx)=>(
          <option value={item?.id || ''} key={indx}>{item?.nicename || ''}</option>
          ))}
        </select>
        {hasError('countryId') && (
        <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['countryId'][0] || ''}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="checkout-street-address">Street Address&nbsp;<span className="text-danger">*</span></label>
        <input
        type="text"
        className={'form-control'+(hasError('streetAddress')?' is-invalid':'')}
        id="checkout-street-address"
        placeholder="Street Address"
        name={'streetAddress'}
        value={data?.streetAddress || ''}
        onChange={handleOnChange} />
        {hasError('streetAddress') && (
        <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['streetAddress'][0] || ''}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="checkout-address">
          Apartment, suite, unit etc.{' '}
          <span className="text-muted">(Optional)</span>
        </label>
        <input 
        type="text" 
        className="form-control" 
        id="checkout-address"
        name={'optAddress'}
        value={data?.optAddress || ''}
        onChange={handleOnChange} />
      </div>
      <div className="form-group">
        <label htmlFor="checkout-city">Town / City&nbsp;<span className="text-danger">*</span></label>
        <input 
        type="text" 
        className={'form-control'+(hasError('city')?' is-invalid':'')}
        id="checkout-city"
        name={'city'}
        value={data?.city || ''}
        onChange={handleOnChange} />
        {hasError('city') && (
        <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['city'][0] || ''}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="checkout-state">State / County&nbsp;<span className="text-danger">*</span></label>
        <input 
        type="text" 
        className={'form-control'+(hasError('state')?' is-invalid':'')}
        id="checkout-state"
        name={'state'}
        value={data?.state || ''}
        onChange={handleOnChange} />
        {hasError('state') && (
        <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['state'][0] || ''}</span>
        )}
      </div>
      <div className="form-group">
        <label htmlFor="checkout-postcode">Postcode / ZIP&nbsp;<span className="text-danger">*</span></label>
        <input 
        type="text" 
        className={'form-control'+(hasError('postcode')?' is-invalid':'')}
        id="checkout-postcode"
        name={'postcode'}
        value={data?.postcode || ''}
        onChange={handleOnChange} />
        {hasError('postcode') && (
        <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['postcode'][0] || ''}</span>
        )}
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="checkout-email">Email address&nbsp;<span className="text-danger">*</span></label>
          <input
          type="email"
          className={'form-control'+(hasError('email')?' is-invalid':'')}
          id="checkout-email"
          placeholder="Email address"
          name={'email'}
          value={data?.email || ''}
          onChange={handleOnChange} />
          {hasError('email') && (
          <span style={{fontSize: '0.8em'}} className="text-danger">{form?.errors['email'][0] || ''}</span>
          )}
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="checkout-phone">Phone</label>
          <input 
          type="text" 
          className="form-control" 
          id="checkout-phone" 
          placeholder="Phone"
          name={'phone'}
          value={data?.phone || ''}
          onChange={handleOnChange} />
        </div>
      </div>
    </>
  );
}
export default Address;
