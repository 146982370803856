/* eslint-disable */
// react
import React from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import Rating from './Rating';
import { cartAddItemPrices } from '../../store/cart';
import { Quickview16Svg } from '../../svg';
import { compareAddItem } from '../../store/compare';
import { quickviewOpen } from '../../store/quickview';
import { url } from '../../services/utils';
import { wishlistAddItem } from '../../store/wishlist';
import noImage from '../../assets/images/no_products_found.png';

function ProductCard(props) {
  const {
    product,
    layout,
    quickviewOpen,
    cartAddItemPrices,
    wishlistAddItem,
    compareAddItem,
    locale
  } = props;
  const containerClasses = classNames('product-card', {
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list': layout === 'list',
    'product-card--layout--horizontal': layout === 'horizontal',
  });

  let badges = [];
  let image;
  let price;
  let features;

  if (null == product?.badges) {
    product.badges = [];
  }

  if (product.badges.includes('sale')) {
    badges.push(<div key="sale" className="product-card__badge product-card__badge--sale">Sale</div>);
  }
  if (product.badges.includes('hot')) {
    badges.push(<div key="hot" className="product-card__badge product-card__badge--hot">Hot</div>);
  }
  if (product.badges.includes('new')) {
    badges.push(<div key="new" className="product-card__badge product-card__badge--new">New</div>);
  }

  badges = badges.length ? <div className="product-card__badges-list">{badges}</div> : null;

  image = (
    <div className="product-card__image product-image">
      <Link to={url.product({
        productSlug: product?.slug || ''
      })} className="product-image__body"> 
        <img className="product-image__img" src={product?.image || noImage} alt="" />
      </Link>
    </div>
  );

  if (product.compareAtPrice) {
    price = (
      <div className="product-card__prices">
      {product?.priceFlag==true && (
      <React.Fragment>
        <span className="product-card__new-price"><Currency value={product.price} /></span>
        {false && (
          <React.Fragment>
            {' '}
            <span className="product-card__old-price"><Currency value={product.compareAtPrice} /></span>
          </React.Fragment>
        )}
      </React.Fragment> 
      )}
      {!product?.priceFlag && (
        <span style={{color: '#ff5722'}}>Please call Us.</span>
      )}
      </div>
    );
  } else {
    price = (
      <div className="product-card__prices">
        {product?.priceFlag==true && (
        <Currency value={product.price} />
        )}
        {!product?.priceFlag && (
        <span style={{color: '#ff5722'}}>Please call Us.</span>
        )}
      </div>
    );
  }

  if (product.attributes && product.attributes.length) {
    features = (
      <ul className="product-card__features-list">
        {product.attributes.filter((x) => x.featured).map((attribute, index) => (
          <li key={index}>{`${attribute.name}: ${attribute.values.map((x) => x.name).join(', ')}`}</li>
        ))}
      </ul>
    );
  }

  return (
    <div className={containerClasses}>
      <AsyncAction
        action={() => quickviewOpen(product.slug,locale)}
        render={({ run, loading }) => (
          <button
            type="button"
            onClick={run}
            className={classNames('product-card__quickview', {
              'product-card__quickview--preload': loading,
            })}
          >
            <Quickview16Svg />
          </button>
        )}
      />
      {badges}
      {image}
      <div className="product-card__info">
        <div className="product-card__name">
          <Link to={url.product({
            productSlug: product?.slug || ''
          })}>{product?.name || ''}</Link>
        </div>
        {false && (
        <div className="product-card__rating">
          <Rating value={product?.rating || 0} />
          <div className=" product-card__rating-legend">{`${product?.reviews || 0} Reviews`}</div>
        </div>
        )}
        {features}
      </div>
      <div className="product-card__actions">
        <div className="product-card__availability">
          Availability:<span className="text-success">In Stock</span>
        </div>
        {price}
        <div className="product-card__buttons">
          {product?.priceFlag==true && (
          <AsyncAction
          action={() => cartAddItemPrices({
            productId: product?.id || null,
            productSku: product?.sku || null,
            productSlug: product?.slug || null,
            productName: product?.name || null,
            productSubtitle: product?.subtitle || null,
            productStatus: product?.status || null,
            productImage:{
              imageMain: product?.image || ''
            }
          }, {
            priceId: product?.priceId || null,
            priceName: product?.priceName || null,
            priceNumber: product?.price || 0,
            priceWeight: product?.priceWeight || 0
          }, [], 1)}
          render={({ run, loading }) => (
            <button
            title="Add to Cart"
            type="button"
            onClick={run}
            style={{width: '100px',padding: '0px 7px'}}
            className={classNames('btn btn-primary product-card__addtocart', {
              'btn-loading': loading,
            })}><i className="fas fa-cart-plus" style={{fontSize: '18px'}} /> Cart</button>
          )} />)}
          <AsyncAction
          action={() => wishlistAddItem(product)}
          render={({ run, loading }) => (
            <button
            title="Add to Wishlist"
            type="button"
            onClick={run}
            style={{width: '60px',padding: '0px 7px'}}
            className={classNames('btn btn-primary product-card__wishlist', {
              'btn-loading': loading,
            })}>
              <i className="fas fa-heart" style={{fontSize: '18px'}} />
            </button>
          )} />
          <AsyncAction
          action={() => compareAddItem(product)}
          render={({ run, loading }) => (
            <button
            title="Add to Quatation"
            type="button"
            onClick={run}
            style={{width: '60px',padding: '0px 7px'}}
            className={classNames('btn btn-primary', {
              'btn-loading': loading,
            })}>
              <i className="fas fa-file-invoice-dollar" style={{fontSize: '18px'}} />
            </button>
          )} />
        </div> 
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  /**
   * product object
   */
  product: PropTypes.object.isRequired,
  /**
   * product card layout
   * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
   */
  layout: PropTypes.oneOf(['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']),
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

const mapDispatchToProps = {
  cartAddItemPrices,
  wishlistAddItem,
  compareAddItem,
  quickviewOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProductCard);
