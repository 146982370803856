/* eslint-disable */
// react
import React, { Component,useEffect,useState } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import departmentsAria from '../../services/departmentsArea';
import languages from '../../i18n';
import StroykaSlick from '../shared/StroykaSlick';

const slickSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    autoplay: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
};
const BlockSlideShow = props =>{
    const {locale,withDepartments} = props;
    
    const media = window.matchMedia('(min-width: 992px)');
    const [departmentsAreaRef,setDepartmentsAreaRef] = useState(null);
    const [direction,setDirection] = useState(languages[locale]);
    const [slidesData,setSlidesData] = useState([]);
    const blockClasses = classNames(
        'block-slideshow block',
        {
            'block-slideshow--layout--full': !withDepartments,
            'block-slideshow--layout--with-departments': withDepartments,
        },
    );

    const layoutClasses = classNames(
        'col-12',
        {
            'col-lg-12': !withDepartments,
            'col-lg-9': withDepartments,
        },
    );

    const setDataDepartmentsAreaRef = (ref) => {
        setDepartmentsAreaRef(ref);

        if (media.matches) {
            departmentsAria.area = ref;
        }
    };

    const callAPIContentCateLanding = (locale) =>{
        fetch(process.env.API_ENDPOINT + "content/listDetailByCategory", {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'accept-language': locale
            },
            body: JSON.stringify({
                slug: 'landing-slider'
            }),
            timeout: process.env.API_TIMEOUT
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
        }).then(data => {
            if (data.reasonCode && data.reasonCode == '0000' && data?.data) {
                var jsonData = data?.data;
                if(jsonData?.contents && jsonData.contents.length>0){
                    var tmpSliders = [];
                    jsonData.contents && jsonData.contents.map(item=>{
                        var tmpDetail = item?.contentDetail || '';
                        var tmpArrDetail = tmpDetail.split('||');
                        tmpSliders.push({
                            title: item?.contentTitle || '',
                            text: tmpArrDetail[0] || '',
                            url: tmpArrDetail[1] || '',
                            image_classic: {
                                ltr: item?.contentImageProfile || '',
                                rtl: item?.contentImageProfile || '',
                            },
                            image_full: {
                                ltr: item?.contentImageProfile || '',
                                rtl: item?.contentImageProfile || '',
                            },
                            image_mobile: {
                                ltr: item?.contentImageProfile || '',
                                rtl: item?.contentImageProfile || '',
                            },
                        });
                    });
                    setSlidesData(tmpSliders);
                }
            }
        }).catch((err) => {
            console.log(err.message);
        });
    };

    useEffect(()=>{
        if(locale){
            setDirection(languages[locale]);
            callAPIContentCateLanding(locale);
        }
    },[locale]);

    useEffect(()=>{
        if (media.addEventListener) {
            media.addEventListener('change', onChangeMedia);
        } else {
            media.addListener(onChangeMedia);
        }
    },[]);

    const renderSlides = slidesData =>{
        return slidesData && slidesData.map((slide, index) => {
            const image = (withDepartments ? slide.image_classic : slide.image_full)[direction?.direction || 'ltr'];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.3)),url(${image})`,
                            backgroundSize: 'auto 100%',
                            backgroundPosition: 'center',
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.3)),url(${slide.image_mobile[direction]})`,
                            backgroundSize: 'auto 100%',
                            backgroundPosition: 'center',
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                        className="block-slideshow__slide-title text-white"
                        dangerouslySetInnerHTML={{ __html: slide?.title }} />
                        <div 
                        className="block-slideshow__slide-text text-white"
                        dangerouslySetInnerHTML={{ __html: slide?.text }}/>
                        <div className="block-slideshow__slide-button">
                            <Link to={slide?.url || '/'} className="btn btn-primary btn-lg">Shop Now</Link>
                        </div>
                    </div>
                </div>
            );
        });
    }

    const onChangeMedia = () => {
        if (media.matches) {
            departmentsAria.area = departmentsAreaRef;
        }
    };

    return (
        <div className={blockClasses}>
            <div className="container">
                <div className="row">
                    {withDepartments && (
                        <div className="col-3 d-lg-block d-none" ref={setDataDepartmentsAreaRef} />
                    )}

                    <div className={layoutClasses}>
                        <div className="block-slideshow__body">
                            {slidesData && slidesData.length > 0 && (
                            <StroykaSlick {...slickSettings}>
                                {renderSlides(slidesData)}
                            </StroykaSlick>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
/*class BlockSlideShow extends Component {
    departmentsAreaRef = null;

    media = window.matchMedia('(min-width: 992px)');

    slides = [
        {
            title: 'Big choice of<br>Plumbing products',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: 'images/slides/slide-1-ltr.jpg',
                rtl: 'images/slides/slide-1-rtl.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-1-full-ltr.jpg',
                rtl: 'images/slides/slide-1-full-rtl.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-1-mobile.jpg',
                rtl: 'images/slides/slide-1-mobile.jpg',
            },
        },
        {
            title: 'Screwdrivers<br>Professional Tools',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: 'images/slides/slide-2-ltr.jpg',
                rtl: 'images/slides/slide-2-rtl.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-2-full-ltr.jpg',
                rtl: 'images/slides/slide-2-full-rtl.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-2-mobile.jpg',
                rtl: 'images/slides/slide-2-mobile.jpg',
            },
        },
        {
            title: 'One more<br>Unique header',
            text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br>Etiam pharetra laoreet dui quis molestie.',
            image_classic: {
                ltr: 'images/slides/slide-3-ltr.jpg',
                rtl: 'images/slides/slide-3-rtl.jpg',
            },
            image_full: {
                ltr: 'images/slides/slide-3-full-ltr.jpg',
                rtl: 'images/slides/slide-3-full-rtl.jpg',
            },
            image_mobile: {
                ltr: 'images/slides/slide-3-mobile.jpg',
                rtl: 'images/slides/slide-3-mobile.jpg',
            },
        },
    ];

    componentDidMount() {
        if (this.media.addEventListener) {
            this.media.addEventListener('change', this.onChangeMedia);
        } else {
            this.media.addListener(this.onChangeMedia);
        }
    }

    componentWillUnmount() {
        departmentsAria.area = null;

        if (this.media.removeEventListener) {
            this.media.removeEventListener('change', this.onChangeMedia);
        } else {
            this.media.removeListener(this.onChangeMedia);
        }
    }

    onChangeMedia = () => {
        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    setDepartmentsAreaRef = (ref) => {
        this.departmentsAreaRef = ref;

        if (this.media.matches) {
            departmentsAria.area = this.departmentsAreaRef;
        }
    };

    render() {
        const { locale, withDepartments } = this.props;
        const { direction } = languages[locale];

        const blockClasses = classNames(
            'block-slideshow block',
            {
                'block-slideshow--layout--full': !withDepartments,
                'block-slideshow--layout--with-departments': withDepartments,
            },
        );

        const layoutClasses = classNames(
            'col-12',
            {
                'col-lg-12': !withDepartments,
                'col-lg-9': withDepartments,
            },
        );

        const slides = this.slides.map((slide, index) => {
            const image = (withDepartments ? slide.image_classic : slide.image_full)[direction];

            return (
                <div key={index} className="block-slideshow__slide">
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
                        style={{
                            backgroundImage: `url(${image})`,
                        }}
                    />
                    <div
                        className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
                        style={{
                            backgroundImage: `url(${slide.image_mobile[direction]})`,
                        }}
                    />
                    <div className="block-slideshow__slide-content">
                        <div
                            className="block-slideshow__slide-title"
                            dangerouslySetInnerHTML={{ __html: slide.title }}
                        />
                        <div
                            className="block-slideshow__slide-text"
                            dangerouslySetInnerHTML={{ __html: slide.text }}
                        />
                        <div className="block-slideshow__slide-button">
                            <Link to="/" className="btn btn-primary btn-lg">Shop Now</Link>
                        </div>
                    </div>
                </div>
            );
        });

        const callAPIContentCateLanding = () =>{
            console.log('xx');
        };

        useEffect(()=>{
            console.log('xx');
        },[locale]);

        return (
            <div className={blockClasses}>
                <div className="container">
                    <div className="row">
                        {withDepartments && (
                            <div className="col-3 d-lg-block d-none" ref={this.setDepartmentsAreaRef} />
                        )}

                        <div className={layoutClasses}>
                            <div className="block-slideshow__body">
                                <StroykaSlick {...slickSettings}>
                                    {slides}
                                </StroykaSlick>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}*/

BlockSlideShow.propTypes = {
    withDepartments: PropTypes.bool,
    /** current locale */
    locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
    withDepartments: false,
};

const mapStateToProps = (state) => ({
    locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
