/* eslint-disable */
import React from 'react';

const Index = props =>{
  const {loading} = props;

  return (
    <React.Fragment>
    {loading && (
    <div style={{
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255,255,255,0.9)',
      top: 0,
      left: 0,
      paddingTop: '100px',
      zIndex: '100',
    }}>
      <div className="d-flex justify-content-center">
        <div className="spinner-border text-primary" role="status"
        style={{width: '3rem',height: '3rem'}}>
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    )}
    </React.Fragment>
  );
}
export default Index;
