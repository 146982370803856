// application
/* eslint import/no-unresolved: 2 */
import enMessages from './messages/en.json';
import thMessages from './messages/th.json';
import zhMessages from './messages/zh.json';

export default {
    en: {
        messages: enMessages,
        direction: 'ltr',
    },
    th: {
        messages: thMessages,
        direction: 'ltr',
    },
    zh: {
        messages: zhMessages,
        direction: 'ltr',
    },
};
