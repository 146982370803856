/* eslint-disable */
// react
import React, { Component } from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from './AsyncAction';
import Currency from './Currency';
import InputNumber from './InputNumber';
import ProductGallery from './ProductGallery';
import Rating from './Rating';
import { cartAddItemPrices } from '../../store/cart';
import { compareAddItem } from '../../store/compare';
import { Wishlist16Svg, Compare16Svg } from '../../svg';
import { wishlistAddItem } from '../../store/wishlist';

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 1,
      productPrice: null
    };
  }

  componentDidMount(){
    if(this.props.product){
      this.initProductPrice(this.props.product);
    }
  }

  initProductPrice = paramProduct =>{
    if(paramProduct?.productPrices && paramProduct?.productPrices.length>0){
      this.setState(state=>({
        ...state,
        productPrice: paramProduct.productPrices[0]
      }));
    }
  }

  handleChangeQuantity = (quantity) => {
    this.setState({ quantity });
  };

  handleChangePrice = (evt,paramProductPrice) =>{
    evt.persist();
    this.setState(state=>({
      ...state,
      productPrice: paramProductPrice
    }));
  }

  render() {
    const {
      product,
      layout,
      wishlistAddItem,
      compareAddItem,
      cartAddItemPrices,
    } = this.props;
    const { quantity,productPrice } = this.state;
    let productList;
    if(product.productImage){
      productList = [];
      if(product.productImage?.imageMain){
        productList.push(product.productImage.imageMain);
      }
      if(product.productImage?.imageDetail && product.productImage.imageDetail.length>0){
        product.productImage.imageDetail.map(v=>{
          if(v.imageDetailMain){
            productList.push(v.imageDetailMain);
          }
        })
      }
    }

    let prices;
    if (product.compareAtPrice) {
      prices = (
        <React.Fragment>
          <span className="product__new-price"><Currency value={productPrice?.priceNumber || 0} /></span>
          {' '}
          <span className="product__old-price"><Currency value={product.compareAtPrice || 0} /></span>
        </React.Fragment>
      );
    } else {
      prices = <Currency value={productPrice?.priceNumber || 0} />;
    }

    return (
      <div className={`product product--layout--${layout}`}>
        <div className="product__content">
          <ProductGallery layout={layout} images={productList || null} />
          <div className="product__info">
            <div className="product__wishlist-compare">
              <AsyncAction
                action={() => wishlistAddItem(product)}
                render={({ run, loading }) => (
                  <button
                    type="button"
                    data-toggle="tooltip"
                    data-placement="right"
                    title="Wishlist"
                    onClick={run}
                    className={classNames('btn btn-sm btn-light btn-svg-icon', {
                      'btn-loading': loading,
                    })}
                  >
                    <Wishlist16Svg />
                  </button>
                )}
              />
              <AsyncAction
              action={() => compareAddItem(product)}
              render={({ run, loading }) => (
                <button
                type="button"
                data-toggle="tooltip"
                data-placement="right"
                title="Compare"
                onClick={run}
                className={classNames('btn btn-sm btn-light btn-svg-icon', {
                  'btn-loading': loading,
                })}>
                  <Compare16Svg />
                </button>
              )} />
            </div>
            <h1 className="product__name">{product?.productName || ''}</h1>
            <div className="product__rating">
              <div className="product__rating-stars">
                <Rating value={0} />
              </div>
              <div className="product__rating-legend">
                <Link to="/">{`${0} Reviews`}</Link>
                <span>/</span>
                <Link to="/">Write A Review</Link>
              </div>
            </div>
            <div className="product__description">{product.productSubtitle || ''}</div>
            <ul className="product__features">
              <li>Speed: 750 RPM</li>
              <li>Power Source: Cordless-Electric</li>
              <li>Battery Cell Type: Lithium</li>
              <li>Voltage: 20 Volts</li>
              <li>Battery Capacity: 2 Ah</li>
            </ul>
            <ul className="product__meta">
              <li className="product__meta-availability">
                Availability:&nbsp;
                {product.productAvailability?<span className="text-success">In Stock</span>:
                <span className="text-danger">Out of Stock</span>
                }
              </li>
              <li>Brand:<Link to="/">{product.productBrand?.productBandName || ''}</Link></li>
              <li>SKU: {product?.productSku || ''}</li>
            </ul>
          </div>

          <div className="product__sidebar">
            <div className="product__availability">
              Availability:&nbsp;
              {product.productAvailability?<span className="text-success">In Stock</span>:
                <span className="text-danger">Out of Stock</span>
                }
            </div>

            <div className="product__prices">
              {product?.priceFlag?prices:<span style={{color: '#ff5722'}}>Please call Us.</span>}
            </div>

            <form className="product__options">
              {product?.priceFlag?<>
              <div className="form-group product__option">
                <div className="product__option-label">Packing size</div>
                <div className="input-radio-label">
                  <div className="input-radio-label__list">
                  {product.productPrices && product.productPrices.map((v,k)=>(
                    <label key={k}>
                      <input onChange={e=>{this.handleChangePrice(e,v)}} 
                      type="radio" 
                      name="product-price" 
                      checked={productPrice?.priceId==v.priceId}
                      value={v.priceId} />
                      <span>{v.priceName}&nbsp;-&nbsp;<Currency value={v.priceNumber || 0} /></span>
                    </label>
                  ))}
                  </div>
                </div>
              </div>
              <div className="form-group product__option">
                <label htmlFor="product-quantity" className="product__option-label">Quantity</label>
                <div className="product__actions">
                  <div className="product__actions-item">
                    <InputNumber
                    id="product-quantity"
                    aria-label="Quantity"
                    className="product__quantity"
                    size="lg"
                    min={1}
                    value={quantity}
                    onChange={this.handleChangeQuantity} />
                  </div>
                  <div className="product__actions-item product__actions-item--addtocart">
                    <AsyncAction
                    action={() => cartAddItemPrices(product, productPrice, [], quantity)}
                    render={({ run, loading }) => (
                      <button
                      type="button"
                      onClick={run}
                      disabled={!quantity}
                      className={classNames('btn btn-primary btn-lg', {
                        'btn-loading': loading,
                      })} >Add to cart</button>
                    )} />
                  </div>
                  <div className="product__actions-item product__actions-item--wishlist">
                    <AsyncAction
                    action={() => wishlistAddItem(product)}
                    render={({ run, loading }) => (
                      <button
                      type="button"
                      data-toggle="tooltip"
                      title="Wishlist"
                      onClick={run}
                      className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                        'btn-loading': loading,
                      })}>
                        <Wishlist16Svg />
                      </button>
                    )} />
                  </div>
                  <div className="product__actions-item product__actions-item--compare">
                    <AsyncAction
                    action={() => compareAddItem(product)}
                    render={({ run, loading }) => (
                      <button
                      type="button"
                      data-toggle="tooltip"
                      title="Compare"
                      onClick={run}
                      className={classNames('btn btn-secondary btn-svg-icon btn-lg', {
                        'btn-loading': loading,
                      })}>
                        <Compare16Svg />
                      </button>
                    )} />
                  </div>
                </div>
              </div>
              </>:null}
            </form>
          </div>

          <div className="product__footer">
            <div className="product__tags tags">
              <div className="tags__list">
              {product.productCategories && product.productCategories.map((v,k)=>(
                <Link key={k} to={`/shop/catalog/${v.cateSlug || ''}`}>{v.cateName}</Link>
              ))}
              </div>
            </div>

            <div className="product__share-links share-links">
              <ul className="share-links__list">
                <li className="share-links__item share-links__item--type--like"><Link to="/">Like</Link></li>
                <li className="share-links__item share-links__item--type--tweet"><Link to="/">Tweet</Link></li>
                <li className="share-links__item share-links__item--type--pin"><Link to="/">Pin It</Link></li>
                {false?<li className="share-links__item share-links__item--type--counter"><Link to="/">4K</Link></li>:null}
              </ul>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Product.propTypes = {
  /** product object */
  product: PropTypes.object.isRequired,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(['standard', 'sidebar', 'columnar', 'quickview']),
};

Product.defaultProps = {
  layout: 'standard',
};

const mapDispatchToProps = {
  cartAddItemPrices,
  wishlistAddItem,
  compareAddItem,
};

export default connect(
  () => ({}),
  mapDispatchToProps,
)(Product);
