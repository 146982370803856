/* eslint-disable */
// react
import React from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// application
import AsyncAction from '../shared/AsyncAction';
import Currency from '../shared/Currency';
import { Cart16Svg } from '../../svg';
import { cartAddItemPrices } from '../../store/cart';
import { url } from '../../services/utils';

function Suggestions(props) {
    const {
        context,
        className,
        products,
        cartAddItemPrices,
    } = props;
    const rootClasses = classNames(`suggestions suggestions--location--${context}`, className);

    const list = (products && products.map((product) => (
        <li key={product.id} className="suggestions__item">
            {product.images && product.images.length > 0 && (
                <div className="suggestions__item-image product-image">
                    <div className="product-image__body">
                        <img className="product-image__img" src={product.images[0]} alt="" />
                    </div>
                </div>
            )}
            <div className="suggestions__item-info">
                <Link className="suggestions__item-name" to={url.product({
                    productSlug: product?.slug || ''
                })}>
                    {product.name}
                </Link>
                <div className="suggestions__item-meta">SKU: {product?.sku || ''}</div>
            </div>
            <div className="suggestions__item-price">
                <Currency value={product.price} />
            </div>
            {context === 'header' && (
                <div className="suggestions__item-actions">
                    <AsyncAction
                        action={() => cartAddItemPrices({
                            productId: product?.id || null,
                            productSku: product?.sku || null,
                            productSlug: product?.slug || null,
                            productName: product?.name || null,
                            productSubtitle: product?.subtitle || null,
                            productStatus: product?.status || null,
                            productImage:{
                                imageMain: product?.image || ''
                            }
                            }, {
                            priceId: product?.priceId || null,
                            priceName: product?.priceName || null,
                            priceNumber: product?.price || 0,
                            priceWeight: product?.priceWeight || 0
                        }, [], 1)}
                        render={({ run, loading }) => (
                            <button
                                type="button"
                                onClick={run}
                                title="Add to cart"
                                className={classNames('btn btn-primary btn-sm btn-svg-icon', {
                                    'btn-loading': loading,
                                })}
                            >
                                <Cart16Svg />
                            </button>
                        )}
                    />
                </div>
            )}
        </li>
    )));

    return (
        <div className={rootClasses}>
            <ul className="suggestions__list">
                {list}
            </ul>
        </div>
    );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    cartAddItemPrices,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Suggestions);
