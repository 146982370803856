/* eslint-disable */
import qs from 'query-string';
//import { getCategories, getCategoryBySlug } from '../fake-server/endpoints/categories';
import {
  getDiscountedProducts,
  getFeaturedProducts,
  getLatestProducts,
  getPopularProducts,
  getProductBySlug,
  getProductsList,
  getRelatedProducts,
  getSuggestions, getTopRatedProducts,
} from '../fake-server/endpoints/products';


const shopApi = {
  /**
   * Returns array of categories.
   *
   * @param {object?} options
   * @param {number?} options.depth
   *
   * @return {Promise<Array<object>>}
   */
  getCategories: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/categories.json?depth=2
     *
     * where:
     * - 2 = options.depth
     */
    // return fetch(`https://example.com/api/categories.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return fetch(process.env.API_ENDPOINT + 'product/category/list',{
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': options?.lang?options.lang:'en'
      },
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data => {
      if(data.reasonCode && data.reasonCode=='0000'){
        return data.data;
      }
    });
  },
  /**
   * Returns category by slug.
   *
   * @param {string} slug
   * @param {object?} options
   * @param {number?} options.depth
   *
   * @return {Promise<object>}
   */
  getCategoryBySlug: (slug, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/categories/power-tools.json?depth=2
     *
     * where:
     * - power-tools = slug
     * - 2           = options.depth
     */
    // return fetch(`https://example.com/api/categories/${slug}.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    //return getCategoryBySlug(slug, options);
    return fetch(process.env.API_ENDPOINT + 'product/category/list',{
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': options?.lang?options.lang:'en'
      },
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data => {
      if(data.reasonCode && data.reasonCode=='0000'){
        return data.data;
      }
    });
  },
  /**
   * Returns product.
   *
   * @param {string} slug
   *
   * @return {Promise<object>}
   */
  getProductBySlug: (slug,locale) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/products/screwdriver-a2017.json
     *
     * where:
     * - screwdriver-a2017 = slug
     */
    // return fetch(`https://example.com/api/products/${slug}.json`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return fetch(process.env.API_ENDPOINT + "product/detail", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': locale
      },
      body: JSON.stringify({
        productSlug: slug
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data =>{
      if(data?.reasonCode && data?.reasonCode=="0000"){
        return data.data;
      }
    });
    //return getProductBySlug(slug);
  },
  /**
   * Returns array of related products.
   *
   * @param {string}  slug
   * @param {object?} options
   * @param {number?} options.limit
   *
   * @return {Promise<Array<object>>}
   */
  getRelatedProducts: (slug, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/products/screwdriver-a2017/related.json&limit=3
     *
     * where:
     * - screwdriver-a2017 = slug
     * - limit             = options.limit
     */
    // return fetch(`https://example.com/api/products/${slug}/related.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getRelatedProducts(slug, options);
  },
  /**
   * Return products list.
   *
   * @param {object?} options
   * @param {number?} options.page
   * @param {number?} options.limit
   * @param {string?} options.sort
   * @param {Object.<string, string>?} filters
   *
   * @return {Promise<object>}
   */
  getProductsList: (options = {}, filters = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/products.json?page=2&limit=12&sort=name_desc&filter_category=screwdriwers&filter_price=500-1000
     *
     * where:
     * - page            = options.page
     * - limit           = options.limit
     * - sort            = options.sort
     * - filter_category = filters.category
     * - filter_price    = filters.price
     */
    // const params = { ...options };
    //
    // Object.keys(filters).forEach((slug) => {
    //     params[`filter_${slug}`] = filters[slug];
    // });
    //
    // return fetch(`https://example.com/api/products.json?${qs.stringify(params)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    //return getProductsList(options, filters);
    var priceRange = filters?.price || null;
    var startPrice = null;
    var endPrice = null;
    var paramLimit = options?.limit || 12;

    if(priceRange){
      var tmpArrPrice = priceRange.trim().split('-');
      if(tmpArrPrice[0]){
        startPrice = parseFloat(tmpArrPrice[0]);
      }
      if(tmpArrPrice[1]){
        endPrice = parseFloat(tmpArrPrice[1]);
      }
    }
    return fetch(process.env.API_ENDPOINT + "product/search", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': options?.locale?options.locale:'en'
      },
      body: JSON.stringify({
        name: filters?.name || null,
        orderSort: options?.sort || null,
        categorySlug: filters?.category || null,
        startPrice: startPrice,
        endPrice: endPrice,
        page: options?.page || 1,
        perPage: paramLimit,
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data =>{
      if(data?.reasonCode && data?.reasonCode=="0000"){
        var jsonData = data.data;
        
        var totalRows = jsonData?.totalRow || 0
        var paramForm = (((options?.page || 1)-1)*paramLimit)+1;
        var paramTo = paramForm + (options?.perPage || paramLimit) -1
        if(paramTo > totalRows){
          paramTo = totalRows
        }

        return {
          page: options?.page || 1,
          limit: options?.perPage || paramLimit,
          total: totalRows,
          pages: jsonData?.totalPage || 0,
          from: paramForm,
          to: paramTo,
          items: jsonData?.products || null
        };
      }
    });
  },
  /**
   * Returns array of featured products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getFeaturedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/featured-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/featured-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getFeaturedProducts(options);
  },
  /**
   * Returns array of latest products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getLatestProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/latest-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/latest-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getLatestProducts(options);
  },
  /**
   * Returns an array of top rated products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getTopRatedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/top-rated-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/top-rated-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getTopRatedProducts(options);
  },
  /**
   * Returns an array of discounted products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getDiscountedProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/discounted-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/discounted-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return getDiscountedProducts(options);
  },
  /**
   * Returns an array of most popular products.
   *
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getPopularProducts: (options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/shop/popular-products.json?limit=3&category=power-tools
     *
     * where:
     * - 3           = options.limit
     * - power-tools = options.category
     */
    // return fetch(`https://example.com/api/popular-products.json?${qs.stringify(options)}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    return fetch(options?.endpoint + "product/searchByOption", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': options?.locale
      },
      body: JSON.stringify({
        option: options?.option || '',
        page: options?.page || 1,
        perPage: options?.perPage || 12
      }),
      timeout: options?.timeout
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data =>{
      if(data?.reasonCode && data?.reasonCode=="0000"){
        var jsonData = data.data;
        return jsonData?.products || null;
      }
    });
    //return getPopularProducts(options);
  },
  /**
   * Returns search suggestions.
   *
   * @param {string}  query
   * @param {object?} options
   * @param {number?} options.limit
   * @param {string?} options.category
   *
   * @return {Promise<Array<object>>}
   */
  getSuggestions: (query, options = {}) => {
    /**
     * This is what your API endpoint might look like:
     *
     * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
     *
     * where:
     * - query    = query
     * - limit    = options.limit
     * - category = options.category
     */
    // return fetch(`https://example.com/api/search/suggestions.json?${qs.stringify({ ...options, query })}`)
    //     .then((response) => response.json());

    // This is for demonstration purposes only. Remove it and use the code above.
    //return getSuggestions(query, options);
    return fetch(process.env.API_ENDPOINT + "product/search", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': 'en'
      },
      body: JSON.stringify({
        categorySlug: query?.category || null,
        name: query || null,
        page: 1,
        perPage: options?.limit || 5
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data =>{
      if(data?.reasonCode && data?.reasonCode=="0000"){
        var jsonData = data.data;

        return jsonData?.products || [];
      }
    });
  },
};


export default shopApi;