export const APICalculate = weight =>{
  return fetch(process.env.API_ENDPOINT + 'thaipost/calculate',{
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      weight: weight || 0
    }),
    timeout: process.env.API_TIMEOUT
  });
};