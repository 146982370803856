/* eslint-disable */
// react
import React,{useState,useEffect} from 'react';

// third-party
import classNames from 'classnames';
import { connect } from 'react-redux';

// application
import MobileLinks from './MobileLinks';
import { Cross20Svg } from '../../svg';
import { currencyChange } from '../../store/currency';
import { localeChange } from '../../store/locale';
import { mobileMenuClose } from '../../store/mobile-menu';

// data stubs
import currencies from '../../data/shopCurrencies';
//import mobileMenuLinks from '../../data/mobileMenu';

const MobileMenu = props =>{
    const {
        mobileMenuState,
        closeMobileMenu,
        changeLocale,
        changeCurrency,
        locale
    } = props;

    const [mobileMenuLinks,setMobileMenuLinks] = useState();

    const classes = classNames('mobilemenu', {
        'mobilemenu--open': mobileMenuState.open,
    });

    const mappingAPIMenu = arrAPIMenu => {
        var arrTmpMenu = [];
        arrAPIMenu && arrAPIMenu.map(item => {
            var tmpMenu = {
                type: 'link',
                label: item?.name || '',
                url: item?.url || '',
                children: null
            };
            if (item?.items && item.items.length > 0) {
                var tmpSubMenu = [];
                item.items && item.items.map(subItem => {
                    tmpSubMenu.push({
                        type: 'link',
                        label: subItem?.name || '',
                        url: subItem?.url || ''
                    });
                });
                tmpMenu.children = tmpSubMenu;
            }
            arrTmpMenu.push(tmpMenu);
        });
        arrTmpMenu.push({
            type: 'button',
            label: 'Language',
            children: [
                { type: 'button', label: 'English', data: { type: 'language', locale: 'en' } },
                { type: 'button', label: 'Thai', data: { type: 'language', locale: 'th' } },
                { type: 'button', label: 'Chinese', data: { type: 'language', locale: 'zh' } }
            ]
        });
        setMobileMenuLinks(arrTmpMenu);
    };

    const callAPIMainMenu = (locale) => {
        fetch(process.env.API_ENDPOINT + 'content/menu', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'accept-language': locale || 'en'
            },
            body: JSON.stringify({
                menuSlug: 'header-menu'
            }),
            timeout: process.env.API_TIMEOUT
        }).then(resp => {
            if (resp.status == 200) {
                return resp.json();
            }
        }).then(data => {
            if (data.reasonCode && data.reasonCode == '0000' && data?.data && data.data.length > 0) {
                mappingAPIMenu(data.data);
            }
        }).catch((err) => {
            console.log(err.message);
        });
    };

    useEffect(() => {
        if (locale) {
            callAPIMainMenu(locale);
        }
    }, [locale]);

    const handleItemClick = (item) => {
        if (item.data) {
            if (item.data.type === 'language') {
                changeLocale(item.data.locale);
                closeMobileMenu();
            }
            if (item.data.type === 'currency') {
                const currency = currencies.find((x) => x.currency.code === item.data.code);

                if (currency) {
                    changeCurrency(currency.currency);
                    closeMobileMenu();
                }
            }
        }
        if (item.type === 'link') {
            closeMobileMenu();
        }
    };

    return (
        <div className={classes}>
            {/* eslint-disable-next-line max-len */}
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
            <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
            <div className="mobilemenu__body">
                <div className="mobilemenu__header">
                    <div className="mobilemenu__title">Menu</div>
                    <button type="button" className="mobilemenu__close" onClick={closeMobileMenu}>
                        <Cross20Svg />
                    </button>
                </div>
                <div className="mobilemenu__content">
                    <MobileLinks links={mobileMenuLinks || []} onItemClick={handleItemClick} />
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mobileMenuState: state.mobileMenu,
    locale: state.locale,
});

const mapDispatchToProps = {
    closeMobileMenu: mobileMenuClose,
    changeLocale: localeChange,
    changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
