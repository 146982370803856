/* eslint-disable */
// react
import React,{useState,useEffect} from 'react';

// third-party
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// application
import AppLink from '../shared/AppLink';
import languages from '../../i18n';
import Megamenu from './Megamenu';
import Menu from './Menu';
import { ArrowRoundedDown9x6Svg } from '../../svg';

// data stubs
//import navLinks from '../../data/headerNavigation';

const NavLinks = props =>{
  const {locale} = props;
  const [navLinks,setNavLinks] = useState();

  const mappingAPIMenu = arrAPIMenu =>{
    var arrTmpMenu = [];
    arrAPIMenu && arrAPIMenu.map(item=>{
      var tmpMenu = {
        title: item?.name || '',
        url: item?.url || '',
        submenu: null
      };
      if(item?.items && item.items.length > 0){
        var tmpSubMenu = [];
        item.items && item.items.map(subItem=>{
          tmpSubMenu.push({
            title: subItem?.name || '',
            url: subItem?.url || ''
          });
        });
        tmpMenu.submenu = {
          type: 'menu',
          menu: tmpSubMenu
        };
      }
      arrTmpMenu.push(tmpMenu);
    });
    setNavLinks(arrTmpMenu);
  };

  const callAPIMainMenu = (locale) =>{
    fetch(process.env.API_ENDPOINT + 'content/menu',{
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        'accept-language': locale || 'en'
      },
      body: JSON.stringify({
        menuSlug: 'header-menu'
      }),
      timeout: process.env.API_TIMEOUT
    }).then(resp => {
      if(resp.status==200){
        return resp.json();
      }
    }).then(data => {
      if(data.reasonCode && data.reasonCode=='0000' && data?.data && data.data.length>0){
        mappingAPIMenu(data.data);
      }
    }).catch((err) => {
      console.log(err.message);
    });
  };

  useEffect(()=>{
    if(locale){
      callAPIMainMenu(locale);
    }
  },[locale]);

  const handleMouseEnter = (event) => {
    const { locale } = props;
    const { direction } = languages[locale];

    const item = event.currentTarget;
    const megamenu = item.querySelector('.nav-links__megamenu');

    if (megamenu) {
      const container = megamenu.offsetParent;
      const containerWidth = container.getBoundingClientRect().width;
      const megamenuWidth = megamenu.getBoundingClientRect().width;
      const itemOffsetLeft = item.offsetLeft;

      if (direction === 'rtl') {
        const itemPosition = containerWidth - (
          itemOffsetLeft + item.getBoundingClientRect().width
        );

        const megamenuPosition = Math.round(
          Math.min(itemPosition, containerWidth - megamenuWidth),
        );

        megamenu.style.left = '';
        megamenu.style.right = `${megamenuPosition}px`;
      } else {
        const megamenuPosition = Math.round(
          Math.min(itemOffsetLeft, containerWidth - megamenuWidth),
        );

        megamenu.style.right = '';
        megamenu.style.left = `${megamenuPosition}px`;
      }
    }
  };

  const mappingMenu = () =>{
    return navLinks && navLinks.map((item, index) => {
      let arrow;
      let submenu;
  
      if (item.submenu) {
        arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
      }
  
      if (item.submenu && item.submenu.type === 'menu') {
        submenu = (
          <div className="nav-links__menu">
            <Menu items={item.submenu.menu} />
          </div>
        );
      }
  
      if (item.submenu && item.submenu.type === 'megamenu') {
        submenu = (
          <div className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}>
            <Megamenu menu={item.submenu.menu} />
          </div>
        );
      }
  
      const classes = classNames('nav-links__item', {
        'nav-links__item--with-submenu': item.submenu,
      });
  
      return (
        <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
          <AppLink to={item.url} {...item.props}>
            <span>
              {item.title}
              {arrow}
            </span>
          </AppLink>
          {submenu}
        </li>
      );
    });
  }

  return (
    <ul className="nav-links__list">
      {navLinks?mappingMenu():null}
    </ul>
  );
}

NavLinks.propTypes = {
  /** current locale */
  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
