export const APIConfig = key =>{
  return fetch(process.env.API_ENDPOINT + 'config/getConfig',{
    method: 'post',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      key: key || null
    }),
    timeout: process.env.API_TIMEOUT
  });
};