/* eslint-disable */
// react
import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
// third-party
import { Helmet } from 'react-helmet-async';

// application
import shopApi from '../../api/shop';
import { useDeferredData, useProductColumns, useProductTabs } from '../../services/hooks';

// blocks
import BlockBanner from '../blocks/BlockBanner';
//import BlockBrands from '../blocks/BlockBrands';
import BlockCategories from '../blocks/BlockCategories';
//import BlockFeatures from '../blocks/BlockFeatures';
//import BlockPosts from '../blocks/BlockPosts';
import BlockProductColumns from '../blocks/BlockProductColumns';
import BlockProducts from '../blocks/BlockProducts';
import BlockProductsCarousel from '../blocks/BlockProductsCarousel';
import BlockSlideShow from '../blocks/BlockSlideShow';

// data stubs
import categories from '../../data/shopBlockCategories';
//import posts from '../../data/blogPosts';
import theme from '../../data/theme';


const HomePageOne = props =>{
  const { locale } = props;

  const [featuredProducts,setFeaturedProducts] = useState({
    data: [],
    isLoading: false,
    tabs: null
  });
  const [bestsellers,setBestsellers] = useState({
    data: []
  });
  const [latestProducts,setLatestProducts] = useState({
    data: [],
    isLoading: false,
    tabs: null
  });

  useEffect(()=>{
    if(locale){
      initLanding(locale);
    }
  },[locale]);

  const initLanding = paramLang =>{
    /**
   * Featured products.
   */
    setFeaturedProducts(featuredProducts=>({
      ...featuredProducts,
      isLoading: true
    }));
    shopApi.getPopularProducts({ 
      endpoint: process.env.API_ENDPOINT, 
      timeout: process.env.API_TIMEOUT,
      option: 'featured',
      perPage: 8,
      page: 1,
      locale: paramLang
    }).then((data)=>{
      setFeaturedProducts(featuredProducts=>({
        ...featuredProducts,
        data: data,
        isLoading: false
      }));
    }).catch(err=>{
      console.log(err);
      setFeaturedProducts(featuredProducts=>({
        ...featuredProducts,
        data: [],
        isLoading: false
      }));
    });

    /**
     * Bestsellers.
     */
    shopApi.getPopularProducts({ 
      endpoint: process.env.API_ENDPOINT, 
      timeout: process.env.API_TIMEOUT,
      option: 'best_sellers',
      perPage: 8,
      page: 1,
      locale: paramLang
    }).then((data)=>{
      setBestsellers(bestsellers=>({
        ...bestsellers,
        data: data
      }));
    }).catch(err=>{
      console.log(err);
      setBestsellers(bestsellers=>({
        ...bestsellers,
        data: []
      }));
    });

    /**
     * Latest products.
     */
    setLatestProducts(latestProducts=>({
      ...latestProducts,
      isLoading: true
    }));
    shopApi.getPopularProducts({ 
      endpoint: process.env.API_ENDPOINT, 
      timeout: process.env.API_TIMEOUT,
      option: 'new_arrivals',
      perPage: 12,
      page: 1,
      locale: paramLang
    }).then((data)=>{
      setLatestProducts(latestProducts=>({
        ...latestProducts,
        data: data,
        isLoading: false
      }));
    }).catch(err=>{
      console.log(err);
      setLatestProducts(latestProducts=>({
        ...latestProducts,
        data: [],
        isLoading: false
      }));
    });
  }

  /**
   * Product columns.
   */
  const columns = useProductColumns(
    useMemo(() => [
      {
        title: 'Top Rated Products',
        source: () => shopApi.getPopularProducts({ 
          endpoint: process.env.API_ENDPOINT, 
          timeout: process.env.API_TIMEOUT,
          option: 'top_rated',
          perPage: 3,
          page: 1,
          locale: locale
         }),
      },
      {
        title: 'Special Offers',
        source: () => shopApi.getPopularProducts({ 
          endpoint: process.env.API_ENDPOINT, 
          timeout: process.env.API_TIMEOUT,
          option: 'special',
          perPage: 3,
          page: 1,
          locale: locale
         }),
      },
      {
        title: 'Bestsellers',
        source: () => shopApi.getPopularProducts({ 
          endpoint: process.env.API_ENDPOINT, 
          timeout: process.env.API_TIMEOUT,
          option: 'best_sellers',
          perPage: 3,
          page: 1,
          locale: locale
         }),
      },
    ], [locale]),
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Home Page One — ${theme.name}`}</title>
      </Helmet>

      {useMemo(() => <BlockSlideShow withDepartments />, [])}

      {/*useMemo(() => <BlockFeatures />, [])*/}

      {useMemo(() => (
        featuredProducts?.data && featuredProducts.data.length>0?
        <BlockProductsCarousel
        title="Featured Products"
        layout="grid-4"
        products={featuredProducts.data || null}
        loading={featuredProducts.isLoading}
        groups={featuredProducts?.tabs || []} />
        :null
      ), [featuredProducts])}

      {useMemo(() => <BlockBanner />, [])}

      {useMemo(() => (
        bestsellers?.data && bestsellers.data.length>0?
        <BlockProducts
        title="Bestsellers"
        layout="large-first"
        featuredProduct={bestsellers.data[0]}
        products={bestsellers.data.slice(1, bestsellers.data.length)} />
        :null
      ), [bestsellers.data])}

      {false?useMemo(() => (
        <BlockCategories
          title="Popular Categories"
          layout="classic"
          categories={categories}
        />
      ), []):null}

      {useMemo(() => (
        latestProducts?.data && latestProducts.data.length>0?
        <BlockProductsCarousel
        title="New Arrivals"
        layout="horizontal"
        rows={2}
        products={latestProducts?.data || null}
        loading={latestProducts.isLoading}
        groups={latestProducts?.tabs || []} />
        :null
      ), [latestProducts])}

      {/*useMemo(() => <BlockPosts title="Latest News" layout="list-sm" posts={posts} />, [])*/}

      {/*useMemo(() => <BlockBrands />, [])*/}

      {useMemo(() => <BlockProductColumns columns={columns} />, [columns])}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(HomePageOne);